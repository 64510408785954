import React from 'react';
import { Box, CircularProgress } from '@mui/material';

interface LoadingProps {
  inline?: boolean;
}

export function Loading({ inline = false }: LoadingProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: inline ? undefined : '25vh',
      }}
    >
      <CircularProgress />
    </Box>
  );
}
