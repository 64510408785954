import { defineMessages } from 'react-intl';

export const languages = defineMessages({
  'Language.en': {
    id: 'Language.en',
    defaultMessage: 'English',
  },
  'Language.fr': {
    id: 'Language.fr',
    defaultMessage: 'French',
  },
});
