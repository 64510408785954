import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useQuery } from 'react-query';
import { Box, Container, Grid, Typography, Stack } from '@mui/material';
import { l10n } from '../../Language';
import { useAppLocale } from '../AppLocaleProvider';
import { QUERY } from '../../queryClient';
import { getAboutInfo } from '../../lib/api/getAboutInfo';
import { HTML } from '../../lib/types';

function getFirstParagraph(text: HTML) {
  const parser = new DOMParser();
  const dom = parser.parseFromString(text, 'text/html');
  const list = Array.from(dom.querySelectorAll('p'));
  // Find first contentful paragraph, otherwise use initial text
  const result =
    list.find((item) => (item.textContent?.trim().length ?? 0) > 0)
      ?.innerHTML ?? text;
  return result;
}

export function HomeAboutBlock() {
  const [locale] = useAppLocale();
  const { data } = useQuery([QUERY.ABOUT], () => getAboutInfo());

  if (!data) {
    return null;
  }

  return (
    <Box sx={{ py: 10 }}>
      <Container sx={{ textAlign: 'center' }}>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 0, sm: 1 }}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="h2"
            textAlign={{ sm: 'left' }}
            paddingBottom={{ xs: '40px' }}
            style={{ fontWeight: 'bold', minWidth: '300px' }}
          >
            <FormattedMessage
              id="components.HomeAboutBlock.title"
              defaultMessage="About RxHelpONE"
            />
          </Typography>
          <Typography variant="body1" sx={{ textAlign: 'left' }}>
            <FormattedMessage
              id="components.HomeAboutBlock.text"
              defaultMessage="Patient payment assistance programs are run by the many brands pharmaceutical manufacturers to provide significant savings on brand name prescription medicines. RxHelpOne.ca connects Canadian patients to these pharmaceutical manufacturers' assistance programs FREE of charge."
            />
          </Typography>
        </Stack>
      </Container>
    </Box>
  );
}
