import React, { useEffect, useState } from 'react';
import { styled, keyframes } from '@mui/material/styles';
import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Link,
} from '@mui/material';
import {
  ArrowLeft as ArrowLeftIcon,
  ArrowRight as ArrowRightIcon,
  Circle as CircleIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import { generatePath, useParams } from 'react-router-dom';
import { QUERY } from '../../queryClient';
import { getHomePagePopups } from '../../lib/api/getHomePagePopups';
import { l10n } from '../../Language';
import { useAppLocale } from '../AppLocaleProvider';
import { ROUTES } from '../../router';
import { convertHTMLToText } from '../../app/.shared/utils/convertUtil';

const progress = keyframes`
  from {
    width: 0;
  }
  to {
    width: 100%
  }
`;

const ANIMATION_DURATION = 5000;
const Progress = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  height: 4,
  animation: `${progress} ${ANIMATION_DURATION}ms infinite linear`,
}));

const KEY = 'promo-popup-show-times';

export function PromoCardPopup() {
  const intl = useIntl();
  const { lang } = useParams();
  const [locale] = useAppLocale();
  const [isOpen, setOpen] = useState(false);
  const [page, setPage] = useState(0);

  const queryParams = { orderBy: 'modifiedDate:desc' } as const;
  const { data, isLoading } = useQuery(
    [QUERY.HOMEPAGE_POPUP, queryParams],
    () => getHomePagePopups(queryParams),
  );

  useEffect(() => {
    if (!data || data.items.length <= 0) {
      return () => {
        // noop
      };
    }

    const attempts = Number.parseInt(localStorage.getItem(KEY) ?? '0', 10);
    if (attempts > 9) {
      return () => {
        // noop
      };
    }

    const timerId = setInterval(() => {
      setPage((p) => (p + 1 >= data.items.length ? 0 : p + 1));
    }, ANIMATION_DURATION);

    setOpen(true);
    localStorage.setItem(KEY, String(attempts + 1));

    return () => {
      clearInterval(timerId);
    };
  }, [locale, data]);

  if (isLoading || !data || data.items.length <= 0) {
    return null;
  }

  return (
    <Dialog
      open={isOpen}
      onClose={() => setOpen(false)}
      fullWidth
      sx={{ textAlign: 'center' }}
    >
      <DialogTitle sx={{ textAlign: 'center', fontSize: 26 }}>
        {l10n(
          {
            en: data.items[page]?.title ?? '',
            fr: data.items[page]?.frTitle ?? '',
          },
          locale,
        )}
      </DialogTitle>
      <IconButton
        aria-label={intl.formatMessage({
          id: 'components.PromoCardPopup.button.close',
          defaultMessage: 'Close',
        })}
        onClick={() => setOpen(false)}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[800],
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent sx={{ px: 6 }}>
        {data.items.length > 1 && (
          <>
            <IconButton
              size="small"
              sx={{ position: 'absolute', left: 8, top: '50%' }}
              onClick={() => {
                setPage((p) => (p - 1 < 0 ? data.items.length - 1 : p - 1));
              }}
            >
              <ArrowLeftIcon />
            </IconButton>
            <IconButton
              size="small"
              sx={{ position: 'absolute', right: 8, top: '50%' }}
              onClick={() => {
                setPage((p) => (p + 1 >= data.items.length ? 0 : p + 1));
              }}
            >
              <ArrowRightIcon />
            </IconButton>
          </>
        )}

        <DialogContentText sx={{ maxHeight: 160, overflow: 'auto' }}>
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: l10n(
                {
                  en: convertHTMLToText(data.items[page]?.text)
                    ? data.items[page]?.text
                    : data.items[page]?.card?.description ??
                      data.items[page]?.product?.promotionalText ??
                      '',
                  fr: convertHTMLToText(data.items[page]?.frText)
                    ? data.items[page]?.frText
                    : data.items[page]?.card?.frDescription ??
                      data.items[page]?.product?.frPromotionalText ??
                      '',
                },
                locale,
              ),
            }}
          />
        </DialogContentText>

        {(data.items[page]?.logoEnContent ||
          data.items[page]?.logoFrContent) && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
            <Box
              component="img"
              src={l10n(
                {
                  en: data.items[page]?.logoEnContent ?? '',
                  fr: data.items[page]?.logoFrContent ?? '',
                },
                locale,
              )}
              sx={{ width: 160 }}
            />
          </Box>
        )}

        {data.items[page]?.landingPage && (
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
            <Link
              href={generatePath(ROUTES.LANDINGS, {
                slug: data.items[page]?.landingPage?.slug ?? 'ERROR',
                lang: lang ?? 'en',
              })}
            >
              <FormattedMessage
                id="1"
                defaultMessage={l10n(
                  {
                    en: data.items[page]?.landingPageText ?? '',
                    fr: data.items[page]?.frLandingPageText ?? '',
                  },
                  locale,
                )}
              />
            </Link>
          </Box>
        )}

        {data.items.length > 1 && (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            {data.items.map((item, idx) => (
              <IconButton
                key={item.encId}
                size="small"
                onClick={() => setPage(idx)}
              >
                <CircleIcon
                  color={page === idx ? 'primary' : undefined}
                  sx={{ fontSize: 10 }}
                />
              </IconButton>
            ))}
          </Box>
        )}
      </DialogContent>

      {data.items.length > 1 && <Progress />}
    </Dialog>
  );
}
