import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Box,
  Button,
  Container,
  Drawer,
  IconButton,
  Toolbar,
} from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import { generatePath, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ROUTES } from '../../router';
import { useThemeFontSize } from '../AppThemeProvider';
import { ThemeFontSize } from '../AppThemeProvider/theme';
import { SiteSearchField } from '../SiteSearchField';

const SX_LEFT_ALIGN = {
  justifyContent: 'flex-start',
  fontWeight: '600 !important',
};

interface MenuContentProps {
  isLeftAlign?: boolean;
}

const ButtonHover = styled.div`
  display: inline-flex;

  .MuiButtonBase-root:hover {
    background-color: #156375;
    color: #fc9b21 !important;
    cursor: pointer !important;
  }
`;

function MenuContent({ isLeftAlign = false }: MenuContentProps) {
  const [size] = useThemeFontSize();
  const sxText =
    size === ThemeFontSize.Large
      ? {
          maxWidth: 245,
          textAlign: 'center',
          lineHeight: 1.2,
          fontWeight: '600',
        }
      : undefined;
  const { lang } = useParams();

  return (
    <>
      <ButtonHover>
        <Button
          style={
            isLeftAlign
              ? { color: '#5abaca', fontWeight: '600' }
              : { color: 'white', fontWeight: '600' }
          }
          sx={isLeftAlign ? SX_LEFT_ALIGN : sxText}
          href={generatePath(ROUTES.HOME, { lang: lang ?? 'en' })}
        >
          <FormattedMessage
            id="components.HeaderMenu.menuItem.home"
            defaultMessage="Home"
          />
        </Button>
      </ButtonHover>
      <ButtonHover>
        <Button
          style={
            isLeftAlign
              ? { color: '#5abaca', fontWeight: '600' }
              : { color: 'white', fontWeight: '600' }
          }
          sx={isLeftAlign ? SX_LEFT_ALIGN : sxText}
          href={generatePath(ROUTES.ABOUT, { lang: lang ?? 'en' })}
        >
          <FormattedMessage
            id="components.HeaderMenu.menuItem.about"
            defaultMessage="About"
          />
        </Button>
      </ButtonHover>
      <ButtonHover>
        <Button
          style={
            isLeftAlign
              ? { color: '#5abaca', fontWeight: '600' }
              : { color: 'white', fontWeight: '600' }
          }
          sx={isLeftAlign ? SX_LEFT_ALIGN : sxText}
          href={generatePath(ROUTES.NEWS_LIST, { lang: lang ?? 'en' })}
        >
          <FormattedMessage
            id="components.HeaderMenu.menuItem.news"
            defaultMessage="What's New"
          />
        </Button>
      </ButtonHover>
      <ButtonHover>
        <Button
          style={
            isLeftAlign
              ? { color: '#5abaca', fontWeight: '600' }
              : { color: 'white', fontWeight: '600' }
          }
          sx={isLeftAlign ? SX_LEFT_ALIGN : sxText}
          href={generatePath(ROUTES.PLAN, { lang: lang ?? 'en' })}
        >
          <FormattedMessage
            id="components.HeaderMenu.menuItem.employers"
            defaultMessage="Benefit Providers"
          />
        </Button>
      </ButtonHover>
      <ButtonHover>
        <Button
          style={
            isLeftAlign
              ? { color: '#5abaca', fontWeight: '600' }
              : { color: 'white', fontWeight: '600' }
          }
          sx={isLeftAlign ? SX_LEFT_ALIGN : sxText}
          href={generatePath(ROUTES.FAQ, { lang: lang ?? 'en' })}
        >
          <FormattedMessage
            id="components.HeaderMenu.menuItem.faq"
            defaultMessage="FAQs"
          />
        </Button>
      </ButtonHover>
      <ButtonHover>
        <Button
          style={
            isLeftAlign
              ? { color: '#5abaca', fontWeight: '600' }
              : { color: 'white', fontWeight: '600' }
          }
          sx={isLeftAlign ? SX_LEFT_ALIGN : sxText}
          href={generatePath(ROUTES.CONTACT, { lang: lang ?? 'en' })}
        >
          <FormattedMessage
            id="components.HeaderMenu.menuItem.contact"
            defaultMessage="Contact"
          />
        </Button>
      </ButtonHover>
    </>
  );
}

export function HeaderMenu() {
  const intl = useIntl();
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <Toolbar
        sx={{
          borderTopWidth: 1,
          borderTopStyle: 'solid',
          borderTopColor: (t) => t.palette.divider,
          borderBottomWidth: 1,
          borderBottomStyle: 'solid',
          borderBottomColor: (t) => t.palette.divider,
          backgroundColor: (t) => t.palette.primary.dark,
          minHeight: '60px !important',
        }}
      >
        <Container sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            size="large"
            aria-label={intl.formatMessage({
              id: 'components.HeaderMenu.button.toggleMenu',
              defaultMessage: 'Toggle Menu',
            })}
            onClick={() => setOpen(true)}
            sx={{ display: { xs: 'block', lg: 'none' }, color: 'white' }}
          >
            <MenuIcon />
          </IconButton>

          <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
            <MenuContent />
          </Box>

          <Box sx={{ ml: 'auto' }}>
            <SiteSearchField />
          </Box>
        </Container>
      </Toolbar>

      <Drawer
        open={isOpen}
        PaperProps={{ sx: { minWidth: 240, textAlign: 'start' } }}
        onClose={() => setOpen(false)}
      >
        <MenuContent isLeftAlign />
      </Drawer>
    </>
  );
}
