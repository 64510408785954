import React from 'react';
import { Box, ButtonBase, Container, Divider, Stack } from '@mui/material';
import { generatePath, useParams } from 'react-router-dom';
import { FontSizeSelector } from '../FontSizeSelector';
import { LanguageSelector } from '../LanguageSelector';
import { useAppLocale } from '../AppLocaleProvider';
import { HeaderMenu } from '../HeaderMenu';
import { ROUTES } from '../../router';
import { l10n } from '../../Language';

interface HeaderProps {
  customLogoUrl?: string;
  hideNavigation?: boolean;
}

export function Header({ customLogoUrl, hideNavigation = false }: HeaderProps) {
  const { lang } = useParams();
  const [locale] = useAppLocale();
  return (
    <>
      <Box sx={{ p: 1 }}>
        <Container>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <ButtonBase
              disabled={hideNavigation}
              href={generatePath(ROUTES.HOME, { lang: lang ?? 'en' })}
            >
              <img
                alt="RxHelp"
                src={
                  customLogoUrl ??
                  l10n(
                    {
                      en: '/2023_RxHelpONElogo1.png',
                      fr: '/2023_RxHelpONElogo1_FR.png',
                    },
                    locale,
                  )
                }
                style={{ maxWidth: 300, maxHeight: 70 }}
              />
            </ButtonBase>

            <Stack direction="row" sx={{ alignItems: 'center' }} spacing={1}>
              <FontSizeSelector />

              <Divider orientation="vertical" />

              <LanguageSelector />
            </Stack>
          </Box>
        </Container>
      </Box>

      {!hideNavigation && <HeaderMenu />}
    </>
  );
}
