import { httpClient } from '../httpClient';
import { LandingItem } from '../types';

export function getLandingPageBySlug(slug: string): Promise<LandingItem> {
  const now = Date.now();
  return httpClient
    .get<void, LandingItem>(`/slugs/${slug}/LandingPage`)
    .then((data) => {
      // TODO: Should be done on BE
      const isActive = data.status === 'active';
      const isExpired = data.end && new Date(data.end).getTime() < now;
      const isPublished = data.start && new Date(data.start).getTime() < now;
      const isProgramActive = data.promoCard.card.status === 'active';
      if (!isActive || isExpired || !isPublished || !isProgramActive) {
        throw new Error('Landing page does not exists');
      }
      return data;
    });
}
