import React from 'react';
import { useQuery } from 'react-query';
import { FormattedMessage } from 'react-intl';
import { styled } from 'styled-components';
import {
  Box,
  ButtonBase,
  Container,
  Grid,
  Typography,
  Stack,
} from '@mui/material';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import ReactPlayer from 'react-player';
import useMediaQuery from '@mui/material/useMediaQuery';
import { SupporterLogo } from '../SupporterLogo';
import { getFeaturedBrands } from '../../lib/api/getFeaturedBrands';
import { QUERY } from '../../queryClient';
import { Loading } from '../Loading';
import { ROUTES } from '../../router';
import { Medication } from '../../lib/types';

const DEFAULT_LOGO = '/no-logo.jpg';

interface HomeFeaturedBrandsProps {
  province?: string;
}

export interface LocationStateProduct {
  product?: Medication;
}

const FeaturedBrandsDiv = styled.div`
  z-index: 1;
  position: relative;
  padding-bottom: 50px;

  &:before {
    height: 23px;
    transform: skewY(-4.8deg);
    top: -58px;
    content: '';
    display: block;
    position: relative;
    background-color: #f39600;
    margin-bottom: -100px;
  }

  &:after {
    height: 110px;
    transform: skewY(-3deg);
    top: 5px;
    content: '';
    display: block;
    position: relative;
    background-color: #0493cc;
    z-index: -1;
    margin-bottom: -100px;
  }
`;

const FeaturedBrandsAfterDiv = styled.div`
  z-index: 1;
  position: relative;
  padding-bottom: 50px;

  &:before {
    height: 24px;
    transform: skewY(-4deg);
    top: 23px;
    content: '';
    display: block;
    position: relative;
    background-color: #0493cc;
    margin-bottom: -100px;
  }

  &:after {
    height: 107px;
    transform: skewY(-3deg);
    top: 100px;
    content: '';
    display: block;
    position: relative;
    background-color: white;
    margin-bottom: -100px;
  }
`;

const FeaturedBrandsBeforeEndDiv = styled.div`
  z-index: 1;
  position: relative;
  padding-bottom: 50px;

  &:before {
    height: 23px;
    transform: skewY(-4.8deg);
    top: 50px;
    content: '';
    display: block;
    position: relative;
    background-color: #f39600;
    margin-bottom: -100px;
  }

  &:after {
    height: 36px;
    transform: skewY(-4deg);
    top: 129px;
    content: '';
    display: block;
    position: relative;
    background-color: #0493cc;
    margin-bottom: -100px;
  }
`;

const VideoContainerDiv = styled.div`
  iframe {
    width: 100%;
    height: 100%;
  }
`;

const VideoContainerMobileDiv = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 16px;
  min-height: 200px;

  iframe {
    width: 100%;
    height: 100%;
  }
`;

export function HomeFeaturedBrands({ province }: HomeFeaturedBrandsProps) {
  const navigate = useNavigate();
  const queryParams = { province };
  const { lang } = useParams();
  const { data, isLoading } = useQuery(
    [QUERY.FEATURED_BRANDS, queryParams],
    () => getFeaturedBrands(queryParams, lang ?? 'en'),
  );
  const isDesktop = useMediaQuery('(min-width:1200px)');

  if (isLoading) {
    return <Loading />;
  }

  if (!data || data.items.length <= 0) {
    return null;
  }

  return (
    <FeaturedBrandsDiv className="featured-brands">
      <FeaturedBrandsAfterDiv className="featured-brands-after" />
      <Container sx={{ paddingTop: '105px' }}>
        <Typography variant="h2" sx={{ fontWeight: 'bold' }}>
          <FormattedMessage
            id="components.HomeFeaturedBrands.title"
            defaultMessage="Featured Brands"
          />
        </Typography>

        <Box sx={{ mb: 6 }} />
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 0, sm: 1 }}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Grid container display={{ sm: 'flex' }} flexWrap={{ sm: 'inherit' }}>
            <Grid
              container
              spacing={2}
              paddingRight={{ sm: '20px' }}
              style={{ justifyContent: 'left' }}
            >
              {data?.items.map((item) => (
                <Grid item key={item.encId} xs={24} sm={12} lg={6}>
                  <ButtonBase
                    disabled={!province}
                    onClick={() => {
                      navigate(
                        `${generatePath(ROUTES.WIZARD, {
                          lang: lang ?? 'en',
                        })}?province=${province}`,
                        {
                          state: {
                            product: item,
                          } satisfies LocationStateProduct,
                        },
                      );
                    }}
                    sx={{ display: 'block', width: '100%' }}
                  >
                    <SupporterLogo url={item.logo?.content ?? DEFAULT_LOGO} />
                  </ButtonBase>
                </Grid>
              ))}
            </Grid>
            <Grid container>
              {isDesktop && (
                <VideoContainerDiv>
                  <ReactPlayer url="https://vimeo.com/829461526" controls />
                </VideoContainerDiv>
              )}
              {!isDesktop && (
                <VideoContainerMobileDiv>
                  <ReactPlayer
                    url="https://vimeo.com/829461526"
                    width="100%"
                    height="100%"
                    controls
                  />
                </VideoContainerMobileDiv>
              )}
            </Grid>
          </Grid>
        </Stack>
      </Container>
      <FeaturedBrandsBeforeEndDiv className="featured-brands-before-end" />
    </FeaturedBrandsDiv>
  );
}
