import { httpClient } from '../httpClient';
import type { DateTime, HTML } from '../types';

interface GetTermsOfUseResponse {
  encId: string;
  details: HTML;
  frDetails: HTML;
  slug: string;
  createDate: DateTime;
  modifiedDate: DateTime;
}

const SLUG = 'terms';

export function getTermsOfUse() {
  return httpClient.get<void, GetTermsOfUseResponse>(`/contents?slug=${SLUG}`);
}
