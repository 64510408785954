import axios, * as others from 'axios';
import { env } from '../../app/.shared/settings/env';

export interface TestApiDetailsData {
  cardType: string;
  province: string;
  postalCode: string;
  heardAbout: string;
  appliesToYou: string;
  title: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  phone2: string;
  address1: string;
  address2: string;
  apt: string;
  city: string;
  country: string;
  drugName: string;
  din: string;
  apiTest: string;
  password: string;
  language: string;
}

export interface TestApiDetailsDataResponse {
  errorDescription: string;
  responseStatus: string;
  downloadPdfLink: string;
  certNumber: string;
  groupCode: string;
}

function GenerateXml(testApiDetailsData: TestApiDetailsData, action: string) {
  const xml = `
  <soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
  <soap:Body>
    <${action} xmlns="http://www.rxhelp.ca/DataCapture">
      ${
        testApiDetailsData.cardType
          ? `<CardType>${testApiDetailsData.cardType}</CardType>`
          : '<CardType>0</CardType>'
      }
      ${
        testApiDetailsData.province
          ? `<Province>${encodeURIComponent(
              testApiDetailsData.province,
            )}</Province>`
          : ''
      }
      ${
        testApiDetailsData.postalCode
          ? `<PostalCode>${encodeURIComponent(
              testApiDetailsData.postalCode,
            )}</PostalCode>`
          : ''
      }
      ${
        testApiDetailsData.heardAbout
          ? `<HeardAbout>${encodeURIComponent(
              testApiDetailsData.heardAbout,
            )}</HeardAbout>`
          : ''
      }
      ${
        testApiDetailsData.appliesToYou
          ? `<Requester>${encodeURIComponent(
              testApiDetailsData.appliesToYou,
            )}</Requester>`
          : ''
      }
      ${
        testApiDetailsData.title
          ? `<Title>${encodeURIComponent(testApiDetailsData.title)}</Title>`
          : ''
      }
      ${
        testApiDetailsData.firstName
          ? `<FirstName>${encodeURIComponent(
              testApiDetailsData.firstName,
            )}</FirstName>`
          : ''
      }
      ${
        testApiDetailsData.lastName
          ? `<LastName>${encodeURIComponent(
              testApiDetailsData.lastName,
            )}</LastName>`
          : ''
      }
      ${
        testApiDetailsData.email
          ? `<Email>${encodeURIComponent(testApiDetailsData.email)}</Email>`
          : ''
      }
      ${
        testApiDetailsData.phone
          ? `<Phone>${encodeURIComponent(testApiDetailsData.phone)}</Phone>`
          : ''
      }
      ${
        testApiDetailsData.phone2
          ? `<Phone2>${encodeURIComponent(testApiDetailsData.phone2)}</Phone2>`
          : ''
      }
      ${
        testApiDetailsData.address1
          ? `<Address>${encodeURIComponent(
              testApiDetailsData.address1,
            )}</Address>`
          : ''
      }
      ${
        testApiDetailsData.address2
          ? `<Address2>${encodeURIComponent(
              testApiDetailsData.address2,
            )}</Address2>`
          : ''
      }
      ${
        testApiDetailsData.apt
          ? `<Apt>${encodeURIComponent(testApiDetailsData.apt)}</Apt>`
          : ''
      }
      ${
        testApiDetailsData.city
          ? `<City>${encodeURIComponent(testApiDetailsData.city)}</City>`
          : ''
      }
      ${
        testApiDetailsData.drugName
          ? `<DrugName>${encodeURIComponent(
              testApiDetailsData.drugName,
            )}</DrugName>`
          : ''
      }
      ${
        testApiDetailsData.din
          ? `<DinNum>${encodeURIComponent(testApiDetailsData.din)}</DinNum>`
          : ''
      }
      ${
        testApiDetailsData.language
          ? `<Language>${encodeURIComponent(
              testApiDetailsData.language,
            )}</Language>`
          : ''
      }
      ${
        testApiDetailsData.password
          ? `<Password>${testApiDetailsData.password}</Password>`
          : ''
      }
    </${action}>
  </soap:Body>
</soap:Envelope>
`;
  return xml;
}

export function testApiDetails(
  testApiDetailsData: TestApiDetailsData,
  action: string,
): Promise<TestApiDetailsDataResponse> {
  // const soapRequest = require('easy-soap-request');

  const url = env.SOAP_API_URL as string;

  // const headers = {
  //   'Content-Type': 'application/soap+xml;charset=UTF-8',
  //   soapAction: `${url}/${action}`,
  // };

  const xml = GenerateXml(testApiDetailsData, action);

  // soapRequest({ url, headers, xml })
  //   .then((response: any) => {
  //     console.log(response.body);
  //     console.log(response.statusCode);
  //   })
  //   .catch((errorBody: any) => {
  //     console.error(errorBody);
  //   }),

  return axios
    .post(url, xml, {
      headers: {
        'Content-Type': 'text/xml',
        soapAction: `${env.SOAP_ACTION_PREFIX}${action}`,
      },
    })
    .then((res) => {
      console.log(res);

      const doc = new DOMParser().parseFromString(res.data, 'text/xml');

      console.info(doc);

      return {
        errorDescription:
          doc.getElementsByTagName('ErrorDescription')[0]?.textContent ?? '',
        responseStatus:
          doc.getElementsByTagName('ResponseStatus')[0]?.textContent ?? '',
        downloadPdfLink:
          doc.getElementsByTagName('DownloadPdfLink')[0]?.textContent ?? '',
        certNumber:
          doc.getElementsByTagName('CertNumber')[0]?.textContent ?? '',
        groupCode: doc.getElementsByTagName('GroupCode')[0]?.textContent ?? '',
      };
    })
    .catch((err) => {
      console.log(err);

      return {
        errorDescription: err,
        responseStatus: '1',
        downloadPdfLink: '',
        certNumber: '',
        groupCode: '',
      };
    });

  // return httpClient.post(
  //   `/pharmacies/${payload.encId}/emails`,
  //   JSON.stringify(payload.email),
  //   { headers: { 'Content-Type': 'application/json' } },
  // );
}
