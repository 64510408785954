import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Box,
  Button,
  Container,
  Divider,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { Helmet } from 'react-helmet';
import { Controller, useForm } from 'react-hook-form';
import {
  testApiDetails,
  TestApiDetailsData,
  TestApiDetailsDataResponse,
} from '../../lib/api/testApiDetails';

interface CardOptionType {
  inputValue?: string;
  id: number;
  name: string;
  firstLetter: string;
}

interface BaseOptionType {
  inputValue?: string;
  id: string;
  name: string;
}

const PROVINCE_LIST: BaseOptionType[] = [
  {
    id: 'AB',
    name: 'Alberta',
  },

  {
    id: 'BC',
    name: 'British Columbia',
  },

  {
    id: 'MB',
    name: 'Manitoba',
  },

  {
    id: 'NB',
    name: 'New Brunswick',
  },

  {
    id: 'NL',
    name: 'Newfoundland and Labrador',
  },

  {
    id: 'NT',
    name: 'Northwest Territories',
  },

  {
    id: 'NS',
    name: 'Nova Scotia',
  },

  {
    id: 'NU',
    name: 'Nunavut',
  },

  {
    id: 'ON',
    name: 'Ontario',
  },

  {
    id: 'PE',
    name: 'Prince Edward Island',
  },

  {
    id: 'QC',
    name: 'Quebec',
  },

  {
    id: 'SK',
    name: 'Saskatchewan',
  },

  {
    id: 'YT',
    name: 'Yukon',
  },
];

const SALUTATION_LIST: BaseOptionType[] = [
  {
    id: 'Attn',

    name: 'Attn.',
  },

  {
    id: 'Dr',

    name: 'Dr.',
  },

  {
    id: 'Mr',

    name: 'Mr.',
  },

  {
    id: 'Mrs',

    name: 'Mrs.',
  },

  {
    id: 'Ms',

    name: 'Ms.',
  },

  {
    id: 'Miss',

    name: 'Miss.',
  },
];

const CARD_TYPE: CardOptionType[] = [
  {
    id: 1,
    name: 'Email card',
    firstLetter: 'E',
  },
  {
    id: 2,
    name: 'PDF card',
    firstLetter: 'P',
  },
  {
    id: 3,
    name: 'Mobile card',
    firstLetter: 'M',
  },
  {
    id: 4,
    name: 'Mail & PDF card',
    firstLetter: 'M',
  },
  {
    id: 5,
    name: 'Mail & Mobile card',
    firstLetter: 'M',
  },
];

const HOW_LIST: BaseOptionType[] = [
  {
    id: 'My Doctor',
    name: 'My Doctor',
  },

  {
    id: 'My Pharmacist',
    name: 'My Pharmacist',
  },

  {
    id: 'Internet search',
    name: 'Internet search',
  },

  {
    id: 'Friends & family',
    name: 'Friends & family',
  },

  {
    id: 'Radio',
    name: 'Radio',
  },

  {
    id: 'Email',
    name: 'Email',
  },

  {
    id: 'Fax',
    name: 'Fax',
  },

  {
    id: 'Direct Mail',
    name: 'Direct Mail',
  },

  {
    id: 'Other',
    name: 'Other',
  },
];

const APPLIES_TO_YOU_LIST: BaseOptionType[] = [
  {
    id: 'Patient',
    name: 'Patient',
  },
  {
    id: 'Caregiver',
    name: 'Caregiver',
  },
  {
    id: 'Other',
    name: 'Other',
  },
];

const LANGUAGE_LIST: BaseOptionType[] = [
  {
    id: 'En',
    name: 'English',
  },
  {
    id: 'Fr',
    name: 'French',
  },
];

const API_TEST_LIST = [
  {
    id: 'PfizerInsert',
    name: 'PfizerInsert',
  },

  {
    id: 'StriveInsert',
    name: 'StriveInsert',
  },

  {
    id: 'ViatrisInsert',
    name: 'ViatrisInsert',
  },

  {
    id: 'AlesseInsert',
    name: 'AlesseInsert',
  },
];

export function PageTestApi() {
  const intl = useIntl();
  const [apiResponse, setApiResponse] =
    useState<TestApiDetailsDataResponse | null>(null);

  const { control, handleSubmit, reset, setValue } = useForm({
    defaultValues: {
      cardType: '',
      province: '',
      postalCode: '',
      heardAbout: '',
      appliesToYou: '',
      title: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      phone2: '',
      address1: '',
      address2: '',
      apt: '',
      city: '',
      country: 'Canada',
      drugName: '',
      din: '',
      language: '',
      apiTest: '',
      password: '',
      result: '',
    },
  });

  const [cardValue, setCardValue] = React.useState<CardOptionType | null>(null);
  const [appliesToYouValue, setAppliesToYouValue] =
    React.useState<BaseOptionType | null>(null);
  const [heardAboutValue, setHeardAboutValue] =
    React.useState<BaseOptionType | null>(null);
  const [provinceValue, setProvinceValue] =
    React.useState<BaseOptionType | null>(null);
  const [salutationValue, setSalutationValue] =
    React.useState<BaseOptionType | null>(null);
  const [languageValue, setLanguageValue] =
    React.useState<BaseOptionType | null>(null);

  const { mutate, isLoading: isSubmitting } = useMutation<
    TestApiDetailsDataResponse,
    unknown,
    TestApiDetailsData
  >(
    ['TestApi'],
    (payload) => {
      if (payload.apiTest === 'PfizerInsert') {
        return testApiDetails(payload, 'PfizerInsert');
      }
      if (payload.apiTest === 'AlesseInsert') {
        return testApiDetails(payload, 'AlesseInsert');
      }
      if (payload.apiTest === 'StriveInsert') {
        return testApiDetails(payload, 'StriveInsert');
      }
      if (payload.apiTest === 'ViatrisInsert') {
        return testApiDetails(payload, 'ViatrisInsert');
      }
      if (payload.apiTest === 'MobileInsert') {
        return testApiDetails(payload, 'MobileInsert');
      }

      return testApiDetails(payload, 'PfizerInsert');
    },
    {
      onSuccess(data) {
        setApiResponse(data);
        setCardValue(null);
        setAppliesToYouValue(null);
        setHeardAboutValue(null);
        setProvinceValue(null);
        setSalutationValue(null);
        setLanguageValue(null);
        reset();
      },
      onError(error) {
        setApiResponse(null);
        reset();
        setCardValue(null);
        setAppliesToYouValue(null);
        setHeardAboutValue(null);
        setProvinceValue(null);
        setSalutationValue(null);
        setLanguageValue(null);
      },
    },
  );

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'components.PageTestApi.meta.title',
            defaultMessage: 'API Test Form',
          })}
        </title>
      </Helmet>

      <Container sx={{ py: 2 }}>
        <Typography variant="h1">
          <FormattedMessage
            id="components.PageTestApi.title"
            defaultMessage="API Test Form"
          />
        </Typography>

        <Typography paragraph sx={{ mt: 2 }}>
          This is the test form for the Pfizer Originals Payment Assistance
          program online registration.
        </Typography>

        <Typography paragraph>
          Please fill in your information below to register for your Pfizer
          Originals Payment Assistance Program card and up to date information
          on future offers that may become available
        </Typography>

        <Box
          sx={{ mt: 2, maxWidth: 'sm' }}
          component="form"
          onSubmit={handleSubmit((data, e) => {
            e?.preventDefault();
            mutate(data);
          })}
        >
          <Typography sx={{ mb: 1 }}>
            <FormattedMessage
              id="components.PageTestApi.indicates"
              defaultMessage="*indicates required fields"
            />
          </Typography>
          <Controller
            control={control}
            name="cardType"
            render={({ field }) => (
              <Autocomplete
                value={cardValue}
                fullWidth
                freeSolo
                disabled={isSubmitting}
                size="small"
                onInputChange={(event, newValue) => {
                  const isExisting = CARD_TYPE.some(
                    (option) => newValue === option.name,
                  );
                  if (newValue !== '' && !isExisting) {
                    setCardValue({
                      id: 0,
                      name: newValue,
                      firstLetter: newValue.charAt(0),
                    });
                    setValue('cardType', '0');
                  }
                }}
                onChange={(event, newValue) => {
                  if (typeof newValue === 'string') {
                    setCardValue({
                      id: 0,
                      name: newValue,
                      firstLetter: newValue.charAt(0),
                    });
                    setValue('cardType', '0');
                  } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    setCardValue({
                      name: newValue.inputValue,
                      firstLetter: newValue.inputValue.charAt(0),
                      id: 0,
                    });
                    setValue('cardType', '0');
                  } else if (newValue) {
                    setCardValue(newValue);
                    setValue('cardType', newValue.id.toString());
                  } else {
                    setCardValue(newValue);
                    setValue('cardType', '0');
                  }
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={CARD_TYPE}
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === 'string') {
                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  // Regular option
                  return option.name;
                }}
                renderOption={(props, option) => (
                  <li {...props}>{option.name}</li>
                )}
                renderInput={(params) => (
                  <TextField {...params} label="Select Card Type" />
                )}
              />
            )}
          />
          <Controller
            control={control}
            name="province"
            render={({ field }) => (
              <Autocomplete
                value={provinceValue}
                fullWidth
                freeSolo
                disabled={isSubmitting}
                size="small"
                onInputChange={(event, newValue) => {
                  const isExisting = PROVINCE_LIST.some(
                    (option) => newValue === option.name,
                  );
                  if (newValue !== '' && !isExisting) {
                    setProvinceValue({
                      id: newValue,
                      name: newValue,
                    });
                    setValue('province', newValue);
                  }
                }}
                onChange={(event, newValue) => {
                  if (typeof newValue === 'string') {
                    setProvinceValue({
                      id: newValue,
                      name: newValue,
                    });
                    setValue('province', newValue);
                  } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    setProvinceValue({
                      name: newValue.inputValue,
                      id: newValue.inputValue,
                    });
                    setValue('province', newValue.inputValue);
                  } else if (newValue) {
                    setProvinceValue(newValue);
                    setValue('province', newValue.id);
                  } else {
                    setProvinceValue(newValue);
                    setValue('province', '');
                  }
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={PROVINCE_LIST}
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === 'string') {
                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  // Regular option
                  return option.name;
                }}
                renderOption={(props, option) => (
                  <li {...props}>{option.name}</li>
                )}
                renderInput={(params) => (
                  <TextField
                    required
                    sx={{ mt: 1 }}
                    {...params}
                    label="Province"
                  />
                )}
              />
            )}
          />
          <Controller
            control={control}
            name="postalCode"
            render={({ field }) => (
              <TextField
                fullWidth
                disabled={isSubmitting}
                size="small"
                sx={{ mt: 1 }}
                label={
                  <FormattedMessage
                    id="components.PageTestApi.field.postalCode"
                    defaultMessage="Postal Code"
                  />
                }
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name="heardAbout"
            render={({ field }) => (
              <Autocomplete
                value={heardAboutValue}
                fullWidth
                freeSolo
                disabled={isSubmitting}
                size="small"
                onInputChange={(event, newValue) => {
                  const isExisting = HOW_LIST.some(
                    (option) => newValue === option.name,
                  );
                  if (newValue !== '' && !isExisting) {
                    setHeardAboutValue({
                      id: newValue,
                      name: newValue,
                    });
                    setValue('heardAbout', newValue);
                  }
                }}
                onChange={(event, newValue) => {
                  if (typeof newValue === 'string') {
                    setHeardAboutValue({
                      id: newValue,
                      name: newValue,
                    });
                    setValue('heardAbout', newValue);
                  } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    setHeardAboutValue({
                      name: newValue.inputValue,
                      id: newValue.inputValue,
                    });
                    setValue('heardAbout', newValue.inputValue);
                  } else if (newValue) {
                    setHeardAboutValue(newValue);
                    setValue('heardAbout', newValue.id);
                  } else {
                    setHeardAboutValue(newValue);
                    setValue('heardAbout', '');
                  }
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={HOW_LIST}
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === 'string') {
                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  // Regular option
                  return option.name;
                }}
                renderOption={(props, option) => (
                  <li {...props}>{option.name}</li>
                )}
                renderInput={(params) => (
                  <TextField
                    sx={{ mt: 1 }}
                    {...params}
                    label="How did you hear about this RxHelp patient program?"
                  />
                )}
              />
            )}
          />
          <Controller
            control={control}
            name="appliesToYou"
            render={({ field }) => (
              <Autocomplete
                value={appliesToYouValue}
                fullWidth
                freeSolo
                disabled={isSubmitting}
                size="small"
                onInputChange={(event, newValue) => {
                  const isExisting = APPLIES_TO_YOU_LIST.some(
                    (option) => newValue === option.name,
                  );
                  if (newValue !== '' && !isExisting) {
                    setAppliesToYouValue({
                      id: newValue,
                      name: newValue,
                    });
                    setValue('appliesToYou', newValue);
                  }
                }}
                onChange={(event, newValue) => {
                  if (typeof newValue === 'string') {
                    setAppliesToYouValue({
                      id: newValue,
                      name: newValue,
                    });
                    setValue('appliesToYou', newValue);
                  } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    setAppliesToYouValue({
                      name: newValue.inputValue,
                      id: newValue.inputValue,
                    });
                    setValue('appliesToYou', newValue.inputValue);
                  } else if (newValue) {
                    setAppliesToYouValue(newValue);
                    setValue('appliesToYou', newValue.id);
                  } else {
                    setAppliesToYouValue(newValue);
                    setValue('appliesToYou', '');
                  }
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={APPLIES_TO_YOU_LIST}
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === 'string') {
                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  // Regular option
                  return option.name;
                }}
                renderOption={(props, option) => (
                  <li {...props}>{option.name}</li>
                )}
                renderInput={(params) => (
                  <TextField
                    sx={{ mt: 1 }}
                    {...params}
                    label="Please indicate which applies to you"
                  />
                )}
              />
            )}
          />

          <Typography sx={{ mt: 2 }}>
            <FormattedMessage
              id="components.PageTestApi.infoBlock"
              defaultMessage="Contact Information"
            />
          </Typography>
          <Controller
            control={control}
            name="title"
            render={({ field }) => (
              <Autocomplete
                value={salutationValue}
                fullWidth
                freeSolo
                disabled={isSubmitting}
                size="small"
                onInputChange={(event, newValue) => {
                  const isExisting = SALUTATION_LIST.some(
                    (option) => newValue === option.name,
                  );
                  if (newValue !== '' && !isExisting) {
                    setSalutationValue({
                      id: newValue,
                      name: newValue,
                    });
                    setValue('title', newValue);
                  }
                }}
                onChange={(event, newValue) => {
                  if (typeof newValue === 'string') {
                    setSalutationValue({
                      id: newValue,
                      name: newValue,
                    });
                    setValue('title', newValue);
                  } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    setSalutationValue({
                      name: newValue.inputValue,
                      id: newValue.inputValue,
                    });
                    setValue('title', newValue.inputValue);
                  } else if (newValue) {
                    setSalutationValue(newValue);
                    setValue('title', newValue.id);
                  } else {
                    setSalutationValue(newValue);
                    setValue('title', '');
                  }
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={SALUTATION_LIST}
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === 'string') {
                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  // Regular option
                  return option.name;
                }}
                renderOption={(props, option) => (
                  <li {...props}>{option.name}</li>
                )}
                renderInput={(params) => (
                  <TextField sx={{ mt: 1 }} {...params} label="Title" />
                )}
              />
            )}
          />

          <Controller
            control={control}
            name="firstName"
            render={({ field }) => (
              <TextField
                fullWidth
                disabled={isSubmitting}
                size="small"
                sx={{ mt: 1 }}
                label={
                  <FormattedMessage
                    id="components.PageTestApi.field.firstName"
                    defaultMessage="First Name"
                  />
                }
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name="lastName"
            render={({ field }) => (
              <TextField
                fullWidth
                disabled={isSubmitting}
                size="small"
                sx={{ mt: 1 }}
                label={
                  <FormattedMessage
                    id="components.PageTestApi.field.lastName"
                    defaultMessage="Last Name"
                  />
                }
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name="email"
            render={({ field }) => (
              <TextField
                fullWidth
                disabled={isSubmitting}
                size="small"
                sx={{ mt: 1 }}
                label={
                  <FormattedMessage
                    id="components.PageTestApi.fieldemailpostalCode"
                    defaultMessage="Email"
                  />
                }
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name="phone"
            render={({ field }) => (
              <TextField
                fullWidth
                disabled={isSubmitting}
                size="small"
                sx={{ mt: 1 }}
                label={
                  <FormattedMessage
                    id="components.PageTestApi.field.phone"
                    defaultMessage="Phone"
                  />
                }
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name="phone2"
            render={({ field }) => (
              <TextField
                fullWidth
                disabled={isSubmitting}
                size="small"
                sx={{ mt: 1 }}
                label={
                  <FormattedMessage
                    id="components.PageTestApi.field.phone2"
                    defaultMessage="Phone 2"
                  />
                }
                {...field}
              />
            )}
          />

          <Controller
            control={control}
            name="address1"
            render={({ field }) => (
              <TextField
                fullWidth
                disabled={isSubmitting}
                size="small"
                sx={{ mt: 1 }}
                label={
                  <FormattedMessage
                    id="components.PageTestApi.field.address1"
                    defaultMessage="Address 1"
                  />
                }
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name="address2"
            render={({ field }) => (
              <TextField
                fullWidth
                disabled={isSubmitting}
                size="small"
                sx={{ mt: 1 }}
                label={
                  <FormattedMessage
                    id="components.PageTestApi.field.address2"
                    defaultMessage="Address 2"
                  />
                }
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name="apt"
            render={({ field }) => (
              <TextField
                fullWidth
                disabled={isSubmitting}
                size="small"
                sx={{ mt: 1 }}
                label={
                  <FormattedMessage
                    id="components.PageTestApi.city.apt"
                    defaultMessage="Apt"
                  />
                }
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name="city"
            render={({ field }) => (
              <TextField
                fullWidth
                disabled={isSubmitting}
                size="small"
                sx={{ mt: 1 }}
                label={
                  <FormattedMessage
                    id="components.PageTestApi.city.postalCode"
                    defaultMessage="City"
                  />
                }
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name="country"
            render={({ field }) => (
              <TextField
                fullWidth
                disabled
                size="small"
                sx={{ mt: 1 }}
                label={
                  <FormattedMessage
                    id="components.PageTestApi.field.country"
                    defaultMessage="Country"
                  />
                }
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name="drugName"
            render={({ field }) => (
              <TextField
                fullWidth
                disabled={isSubmitting}
                size="small"
                sx={{ mt: 1 }}
                label={
                  <FormattedMessage
                    id="components.PageTestApi.field.drugName"
                    defaultMessage="Drug Name"
                  />
                }
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name="din"
            render={({ field }) => (
              <TextField
                fullWidth
                disabled={isSubmitting}
                size="small"
                sx={{ mt: 1 }}
                label={
                  <FormattedMessage
                    id="components.PageTestApi.field.din"
                    defaultMessage="DIN"
                  />
                }
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name="language"
            render={({ field }) => (
              <Autocomplete
                value={languageValue}
                fullWidth
                freeSolo
                disabled={isSubmitting}
                size="small"
                onInputChange={(event, newValue) => {
                  const isExisting = LANGUAGE_LIST.some(
                    (option) => newValue === option.name,
                  );
                  if (newValue !== '' && !isExisting) {
                    setLanguageValue({
                      id: newValue,
                      name: newValue,
                    });
                    setValue('language', newValue);
                  }
                }}
                onChange={(event, newValue) => {
                  if (typeof newValue === 'string') {
                    setLanguageValue({
                      id: newValue,
                      name: newValue,
                    });
                    setValue('language', newValue);
                  } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    setLanguageValue({
                      name: newValue.inputValue,
                      id: newValue.inputValue,
                    });
                    setValue('language', newValue.inputValue);
                  } else if (newValue) {
                    setLanguageValue(newValue);
                    setValue('language', newValue.id);
                  } else {
                    setLanguageValue(newValue);
                    setValue('language', '');
                  }
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={LANGUAGE_LIST}
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === 'string') {
                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  // Regular option
                  return option.name;
                }}
                renderOption={(props, option) => (
                  <li {...props}>{option.name}</li>
                )}
                renderInput={(params) => (
                  <TextField
                    required
                    sx={{ mt: 1 }}
                    {...params}
                    label="Language"
                  />
                )}
              />
            )}
          />
          <Controller
            control={control}
            name="password"
            render={({ field }) => (
              <TextField
                type="password"
                fullWidth
                disabled={isSubmitting}
                size="small"
                required
                sx={{ mt: 1 }}
                label={
                  <FormattedMessage
                    id="components.PageTestApi.field.password"
                    defaultMessage="Password"
                  />
                }
                {...field}
              />
            )}
          />

          <Divider sx={{ my: 3 }} />

          <Controller
            control={control}
            name="apiTest"
            render={({ field }) => (
              <TextField
                fullWidth
                disabled={isSubmitting}
                size="small"
                required
                select
                sx={{ mt: 1 }}
                label={
                  <FormattedMessage
                    id="components.PageTestApi.field.apiTest"
                    defaultMessage="Select API to test"
                  />
                }
                {...field}
              >
                <MenuItem value="">Select One</MenuItem>
                {API_TEST_LIST.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />

          <Box sx={{ mb: 3 }} />
          <Controller
            control={control}
            name="result"
            render={({ field }) => (
              <TextField
                color={
                  apiResponse && apiResponse.responseStatus === '0'
                    ? 'success'
                    : 'error'
                }
                multiline
                rows={2}
                fullWidth
                disabled
                size="small"
                sx={{ mt: 1 }}
                value={
                  apiResponse
                    ? `Return Code: ${apiResponse.responseStatus}${
                        apiResponse.errorDescription &&
                        apiResponse.errorDescription.length > 0
                          ? `\nDescription: ${apiResponse.errorDescription}`
                          : ''
                      }`
                    : ''
                }
              />
            )}
          />

          <Box sx={{ mb: 3 }} />
          <Button
            type="submit"
            variant="contained"
            disabled={isSubmitting}
            sx={{ borderRadius: 100 }}
          >
            <FormattedMessage
              id="components.PageTestApi.submit"
              defaultMessage="Test API"
            />
          </Button>

          <Box sx={{ mb: 3 }} />
          {apiResponse &&
            apiResponse.responseStatus === '0' &&
            apiResponse.downloadPdfLink &&
            apiResponse.downloadPdfLink.length > 0 && (
              <Button
                download
                type="button"
                target="_blank"
                href={apiResponse.downloadPdfLink}
                variant="contained"
              >
                <FormattedMessage
                  id="components.PageWizard.button.downloadCardPdf"
                  defaultMessage="Download PDF Card"
                />
              </Button>
            )}
        </Box>
      </Container>
    </>
  );
}
