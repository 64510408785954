import { Box } from '@mui/material';
import React from 'react';

interface SupporterLogoProps {
  url: string;
}

export function SupporterLogo({ url }: SupporterLogoProps) {
  return (
    <Box
      sx={{
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: (t) => t.palette.divider,
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        p: 1,
      }}
    >
      <Box
        component="img"
        height={100}
        src={url}
        sx={{ maxWidth: '100%', objectFit: 'contain' }}
      />
    </Box>
  );
}
