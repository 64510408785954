import React from 'react';
import { Helmet } from 'react-helmet';
import CssBaseline from '@mui/material/CssBaseline';
import { Box } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import { Header } from '../../components/Header';
import { Footer } from '../../components/Footer';
import { useAppLocale } from '../../components/AppLocaleProvider';
import { Language } from '../../Language';

interface DefaultLayoutProps {
  children: React.ReactNode;
  hideHeader?: boolean;
  hideFooter?: boolean;
  hideNavigation?: boolean;
  customLogoUrl?: string | undefined;
}

export function DefaultLayout({
  children,
  customLogoUrl,
  hideHeader = false,
  hideNavigation = false,
  hideFooter = false,
}: DefaultLayoutProps) {
  const { lang } = useParams();

  const [locale, setLocale] = useAppLocale();

  if (lang === 'fr' && locale === Language.English) {
    setLocale(Language.French);
  } else if (lang !== 'fr' && locale === Language.French) {
    setLocale(Language.English);
  }

  return (
    <>
      <Helmet>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Helmet>
      <CssBaseline />

      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        {!hideHeader && (
          <Header
            hideNavigation={hideNavigation}
            customLogoUrl={customLogoUrl}
          />
        )}

        <Box sx={{ flex: 1 }}>{children}</Box>

        {!hideFooter && (
          <Box sx={{ flex: 0 }}>
            <Footer />
          </Box>
        )}
      </Box>
    </>
  );
}
