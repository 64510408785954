declare var CONFIG: any;

const {
  GMAP_KEY = '',
  GOOGLE_RECAPTCHA_PUBLIC_KEY = '',
  SOAP_ACTION_PREFIX = '',
  SOAP_API_URL = '',
} = CONFIG;

const env = {
  GMAP_KEY,
  GOOGLE_RECAPTCHA_PUBLIC_KEY,
  SOAP_ACTION_PREFIX,
  SOAP_API_URL,
};

export {env};
