import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Box,
  Button,
  Grid,
  Link,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Phone as PhoneIcon } from '@mui/icons-material';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import {
  EmailPharmacyDetailsData,
  emailPharmacyDetails,
} from '../../lib/api/emailPharmacyDetails';

interface PharmacyCardProps {
  encId: string;
  title: string;
  address: string;
  phone: string;
}

export function PharmacyCard({
  encId,
  title,
  address,
  phone,
}: PharmacyCardProps) {
  const intl = useIntl();

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      email: '',
    },
  });

  const { mutate, isLoading: isSubmitting } = useMutation<
    unknown,
    unknown,
    EmailPharmacyDetailsData
  >(['EmailPharmacyDetails'], (payload) => emailPharmacyDetails(payload), {
    onSuccess() {
      toast.success(
        intl.formatMessage({
          id: 'components.PharmacyCard.notification.success',
          defaultMessage: 'Pharmacy details sent to email',
        }),
      );
      reset();
    },
    onError(error) {
      toast.error(String(error));
    },
  });

  return (
    <Paper sx={{ p: 2 }}>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h3">{title}</Typography>
          <Typography variant="body1">{address}</Typography>
          <Stack direction="row" spacing={1}>
            <PhoneIcon />
            <Link href={`tel:${phone}`}>{phone}</Link>
          </Stack>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box
            component="form"
            onSubmit={handleSubmit((payload) => {
              mutate({ encId, ...payload });
            })}
            sx={{ ml: 'auto', maxWidth: { xs: '100%', sm: 400 } }}
          >
            <Controller
              control={control}
              name="email"
              render={({ field }) => (
                <TextField
                  fullWidth
                  disabled={isSubmitting}
                  size="small"
                  margin="none"
                  type="email"
                  required
                  label={
                    <FormattedMessage
                      id="components.PharmacyCard.field.email"
                      defaultMessage="Email"
                    />
                  }
                  {...field}
                />
              )}
            />

            <Box sx={{ mb: 1 }} />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={isSubmitting}
            >
              <FormattedMessage
                id="components.PharmacyCard.button.sendEmail"
                defaultMessage="Email me the details"
              />
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
}
