import { httpClient } from '../httpClient';
import { DateTime, HTML } from '../types';

interface GetNewsDetailsResponse {
  encId: string;
  status: 'active' | 'inactive';
  title: string;
  frTitle: string;
  content: HTML;
  frContent: HTML;
  createDate: DateTime;
  modifiedDate: DateTime;
  startDate: DateTime;
  isPermanent: boolean;
  photo?: {
    encId: string;
    encIdFr: string;
    name: string;
    frName: string;
    url: string;
    frUrl: string;
  };
}

interface GetNewsDetailsInternalParams {
  expand: string;
}

export function getNewsDetails(id: string) {
  return httpClient.get<GetNewsDetailsInternalParams, GetNewsDetailsResponse>(
    `/news/${id}`,
    {
      expand: 'photo',
    },
  );
}
