import React from 'react';
import { Box, ButtonBase, Stack, Typography } from '@mui/material';

interface ListItemProps {
  id: string;
  url: string;
  title: string;
  label: string;
  promoText: string;
  selected: boolean;
  onClick?: (id: string) => void;
  noButton?: boolean;
}

export function ListItem({
  id,
  title,
  label,
  promoText,
  url,
  selected,
  onClick,
  noButton,
}: ListItemProps) {
  return (
    <ButtonBase
      disableRipple={noButton}
      sx={{ width: '100%', cursor: noButton ? 'default' : 'hand' }}
      onClick={() => {
        if (onClick) {
          onClick(id);
        }
      }}
    >
      <Stack direction="column" spacing={2} sx={{ width: '100%' }}>
        <Box
          sx={
            noButton
              ? {
                  height: 180,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  p: 1,
                }
              : {
                  borderWidth: 4,
                  height: 180,
                  borderStyle: 'solid',
                  borderColor: (t) =>
                    selected ? t.palette.primary.main : t.palette.divider,
                  backgroundColor: 'white',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  p: 1,
                }
          }
        >
          <Box
            component="img"
            src={url}
            sx={{ maxWidth: '100%', maxHeight: '100%' }}
          />
        </Box>
        <Box
          sx={{
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            fontSize: 14,
          }}
        >
          <div
            style={{ overflow: 'hidden' }}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
        </Box>
        <Typography
          sx={{ fontSize: 14 }}
          style={{ marginTop: 0 }}
          title={label}
        >
          {label}
        </Typography>
        <Typography noWrap title={promoText}>
          {promoText}
        </Typography>
      </Stack>
    </ButtonBase>
  );
}
