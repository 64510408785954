import axios, { AxiosRequestConfig, isAxiosError } from 'axios';
import { ApiError } from './types';

const instance = axios.create({
  baseURL: '/api/',
});

type HttpClientRequestConfig = Pick<AxiosRequestConfig, 'headers' | 'baseURL'>;

export const httpClient = {
  get<TParams, TData>(
    url: string,
    params?: TParams,
    config: HttpClientRequestConfig = {},
  ): Promise<TData> {
    return instance
      .get<TData>(url, { params, ...config })
      .then(({ data }) => data);
  },

  post<TParams, TData>(
    url: string,
    payload?: TParams,
    config?: HttpClientRequestConfig,
  ): Promise<TData> {
    return instance.post<TData>(url, payload, config).then(({ data }) => data);
  },

  put<TParams, TData>(
    url: string,
    payload?: TParams,
    config?: HttpClientRequestConfig,
  ): Promise<TData> {
    return instance.put<TData>(url, payload, config).then(({ data }) => data);
  },

  delete<TParams, TData>(
    url: string,
    params?: TParams,
    config: HttpClientRequestConfig = {},
  ): Promise<TData> {
    return instance
      .delete<TData>(url, { params, ...config })
      .then(({ data }) => data);
  },
};

function isApiError(error: unknown): error is ApiError {
  if (
    typeof error === 'object' &&
    error !== null &&
    'code' in error &&
    typeof error.code === 'number' &&
    'codeType' in error &&
    typeof error.codeType === 'string' &&
    'description' in error &&
    typeof error.description === 'string' &&
    'dateTime' in error &&
    typeof error.dateTime === 'string' &&
    !Number.isNaN(Date.parse(error.dateTime))
  ) {
    return true;
  }
  return false;
}

export function getApiError(error: unknown): ApiError | null {
  if (isAxiosError(error) && isApiError(error.response?.data)) {
    return error.response?.data as ApiError;
  }
  return null;
}
