import { Language } from '../../Language';
import { httpClient } from '../httpClient';
import { Medication, PositiveInteger } from '../types';

interface SearchMedicationsResponse {
  /**
   * List of available first chars in database through pagination
   */
  chars: Array<string>;
  pageNumber: PositiveInteger;
  pageSize: PositiveInteger;
  totalPages: PositiveInteger;
  totalRecords: number;
  items: Array<Medication>;
}

export interface SearchMedicationsParams {
  page: PositiveInteger;
  pageSize: PositiveInteger;

  /**
   * First letter of medication name
   */
  alpha?: string;

  /**
   * Using for check `isMultiAssistance` for specified language,
   * and also check that certificate pool exists for this language.
   */
  language: Language;

  /**
   * Province code
   * @example 'AB'
   */
  province: string;

  /**
   * Drug name, generic name or DIN
   */
  searchCriteria: string;

  /**
   * `encId` of program for filter medications for landing page
   */
  encCardId?: string;

  orderBy?: string;
}

interface SearchMedicationsInternalParams extends SearchMedicationsParams {
  /**
   * Filters products from programs that have multiple assistance level.
   * We should not show products from such programs when user search medications
   * from home page, them should be shown only if user comes from Landing Page or Referral Partner.
   *
   * Must be used in a couple with `language`
   */
  isMultiAssistance?: boolean;
}

export function searchMedications(
  params: SearchMedicationsParams,
): Promise<SearchMedicationsResponse> {
  return httpClient.get<
    SearchMedicationsInternalParams,
    SearchMedicationsResponse
  >('/products', {
    ...params,
    ...(params.encCardId ? {} : { isMultiAssistance: false }),
  });
}
