import { httpClient } from '../httpClient';
import type { DateTime, HTML } from '../types';

export interface LogoItem {
  encId: string;
  url: string;
  order: number;
}

interface GetEmployersResponse {
  content: HTML;
  frContent: HTML;
  createDate: DateTime;
  modifiedDate: DateTime;
  logos: Array<LogoItem>;
}

export function getEmployers() {
  return httpClient.get<void, GetEmployersResponse>('/plan?expand=logos');
}
