import React from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Alert, Box, Container, Link, Typography } from '@mui/material';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import { DefaultLayout } from '../../layouts/DefaultLayout';
import { getNewsDetails } from '../../lib/api/getNewsDetails';
import { Loading } from '../../components/Loading';
import { Language, l10n } from '../../Language';
import { useAppLocale } from '../../components/AppLocaleProvider';
import { ROUTES } from '../../router';
import { QUERY } from '../../queryClient';

export function PageNewsDetails() {
  const intl = useIntl();
  const { id, lang } = useParams();
  const [locale] = useAppLocale();
  const { data, isLoading, error } = useQuery(
    [QUERY.NEWS, id],
    () => getNewsDetails(id ?? 'ERROR'),
    { enabled: !!id },
  );

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'components.PageNewsDetails.meta.title',
            defaultMessage: "What's News Details",
          })}
        </title>
      </Helmet>

      <DefaultLayout>
        <Container sx={{ py: 2 }}>
          {/* eslint-disable-next-line no-nested-ternary */}
          {isLoading ? (
            <Loading />
          ) : error || !data ? (
            <Alert severity="error">{String(error)}</Alert>
          ) : (
            <>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography variant="h1" component="div">
                  <FormattedMessage
                    id="components.PageNewsDetails.header"
                    defaultMessage="What's New"
                  />
                </Typography>

                <Link
                  href={generatePath(ROUTES.NEWS_LIST, { lang: lang ?? 'en' })}
                >
                  <FormattedMessage
                    id="components.PageNewsDetails.button.back"
                    defaultMessage="Back to What's New"
                  />
                </Link>
              </Box>

              <Box sx={{ mb: 2 }} />

              <Box component="article">
                <Typography variant="body1" color="text.secondary">
                  <FormattedDate
                    month="long"
                    year="numeric"
                    day="numeric"
                    value={data.startDate}
                  />
                </Typography>
                <Typography variant="h1" color="primary">
                  {l10n({ en: data.title, fr: data.frTitle }, locale)}
                </Typography>
                {((!!data.photo?.url && locale === Language.English) ||
                  (!!data.photo?.frUrl && locale === Language.French)) && (
                  <Box
                    component="img"
                    alt={l10n(
                      { en: data.photo.name, fr: data.photo.frName },
                      locale,
                    )}
                    src={l10n(
                      { en: data.photo.url, fr: data.photo.frUrl },
                      locale,
                    )}
                    sx={{
                      float: 'left',
                      m: 2,
                      ml: 0,
                      maxWidth: 280,
                      borderRadius: 2,
                    }}
                  />
                )}
                <div
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: l10n(
                      { en: data.content, fr: data.frContent },
                      locale,
                    ),
                  }}
                />
              </Box>
            </>
          )}
        </Container>
      </DefaultLayout>
    </>
  );
}
