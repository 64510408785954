import { LinkProps, createTheme } from '@mui/material';
import { ReactRouterLinkAdapter } from '../ReactRouterLinkAdapter';

export enum ThemeFontSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

const FONT_SIZE_MAP = {
  [ThemeFontSize.Small]: 13,
  [ThemeFontSize.Medium]: 15,
  [ThemeFontSize.Large]: 20,
} as const;

export const makeTheme = (size: ThemeFontSize) =>
  createTheme({
    palette: {
      primary: {
        light: '#d5e9ea',
        main: '#5abaca',
        dark: '#0493cc',
      },
      secondary: {
        main: '#F39600',
      },
      grey: {
        800: '#4D4D4D',
      },
    },
    typography: {
      allVariants: {
        fontSize: FONT_SIZE_MAP[size],
      },
      h1: {
        fontSize: FONT_SIZE_MAP[size] * 2,
        fontWeight: 'normal',
      },
      h2: {
        fontSize: FONT_SIZE_MAP[size] * 1.8,
        fontWeight: 'normal',
      },
      h3: {
        fontSize: FONT_SIZE_MAP[size] * 1.5,
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          html: {
            height: '100%',
          },
          body: {
            height: '100%',
          },
          '#root': { height: '100%' },
        },
      },
      MuiLink: {
        defaultProps: {
          component: ReactRouterLinkAdapter,
        } as LinkProps,
      },
      MuiButtonBase: {
        defaultProps: {
          LinkComponent: ReactRouterLinkAdapter,
        },
      },
    },
  });
