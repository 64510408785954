import { httpClient } from '../httpClient';
import type { DateTime, PositiveInteger } from '../types';

interface SupporterLogo {
  encFileId: string;
  order: number;
  createDate: DateTime;
  encId: string;
  name: string;
  url: string;
}

interface FetchProgramSupportersResponse {
  pageNumber: PositiveInteger;
  pageSize: PositiveInteger;
  totalPages: PositiveInteger;
  totalRecords: number;
  items: Array<SupporterLogo>;
}

export interface FetchProgramSupportersParams {
  orderBy?: `${keyof SupporterLogo}:${'asc' | 'desc'}`;
  page?: PositiveInteger;
  pageSize?: PositiveInteger;
}

export function fetchProgramSupporters(params?: FetchProgramSupportersParams) {
  return httpClient.get<
    FetchProgramSupportersParams,
    FetchProgramSupportersResponse
  >('/programSupportLogos', params);
}
