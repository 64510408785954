import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { Box, Container, Typography, Grid, Stack, Button } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams, generatePath } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { styled } from 'styled-components';
import { DefaultLayout } from '../../layouts/DefaultLayout';
import { Language, l10n } from '../../Language';
import { useAppLocale } from '../../components/AppLocaleProvider';
import { QUERY } from '../../queryClient';
import { Loading } from '../../components/Loading';
import { ROUTES } from '../../router';
import { getReferralPartnerBySlug } from '../../lib/api/getReferralPartnerBySlug';
import { ProvinceAssistanceSelector } from '../../components/ProvinceAssistanceSelector';
import { FetchProgramCertPoolResponseItem } from '../../lib/api/fetchProgramCertPool';
import { ProductInfo } from '../../lib/types';
import { RxHelpCard } from '../../components/RxHelpCard';
import { convertHTMLToString } from '../../app/.shared/utils/convertUtil';
import { CertificateGenerationPoint } from '../../CertificateGenerationPoint';

const ProvinceDiv = styled.div`
  display: inline-grid;
  width: 100%;
  max-width: 570px;

  .MuiButton-root.Mui-disabled {
    background-color: rgba(243, 150, 0, 0.12);
  }

  .MuiButton-root {
    background-color: rgba(243, 150, 0);
  }

  .MuiInputBase-root {
    background-color: white;
  }
`;

const buttonStyle = {
  height: 'fit-content',
  marginTop: '1rem',
  borderRadius: '100px',
  padding: '16px 42px',
  color: '#ffffff',
  textDecoration: 'none',
  fontFamily: 'Open Sans, sans-serif',
  fontWeight: 700,
  fontSize: '1.25em',
};

const PartnersDiv = styled.div`
  z-index: 1;
  position: relative;
  padding-bottom: 50px;

  &:before {
    height: 23px;
    transform: skewY(-4.8deg);
    top: -58px;
    content: '';
    display: block;
    position: relative;
    background-color: #f39600;
    margin-bottom: -100px;
  }
`;

const PartnersAfterDiv = styled.div`
  z-index: 1;
  position: relative;
  padding-bottom: 50px;

  &:before {
    height: 24px;
    transform: skewY(-4deg);
    top: 23px;
    content: '';
    display: block;
    position: relative;
    background-color: #0493cc;
    margin-bottom: -100px;
  }

  &:after {
    height: 107px;
    transform: skewY(-3deg);
    top: 100px;
    content: '';
    display: block;
    position: relative;
    background-color: white;
    margin-bottom: -100px;
  }
`;

function base64toBlob(base64Data: string) {
  const sliceSize = 1024;
  const byteCharacters = atob(base64Data);
  const bytesLength = byteCharacters.length;
  const slicesCount = Math.ceil(bytesLength / sliceSize);
  const byteArrays = new Array(slicesCount);

  for (let sliceIndex = 0; sliceIndex < slicesCount; sliceIndex += 1) {
    const begin = sliceIndex * sliceSize;
    const end = Math.min(begin + sliceSize, bytesLength);

    const bytes = new Array(end - begin);
    for (let offset = begin, i = 0; offset < end; i += 1, offset += 1) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: 'application/pdf' });
}

function openBase64NewTab(base64Pdf: string): void {
  const blob = base64toBlob(base64Pdf);
  if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
    (window.navigator as any).msSaveOrOpenBlob(blob, 'pdfBase64.pdf');
  } else {
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl);
  }
}

export function PageReferralPartner() {
  const { slug, lang } = useParams();
  const navigate = useNavigate();
  const [locale] = useAppLocale();
  const { isLoading, data } = useQuery([QUERY.REFERRAL_PARTNER, slug], () =>
    getReferralPartnerBySlug(slug ?? 'ERROR'),
  );
  const [provinceAssistance, setProvinceAssistance] =
    useState<FetchProgramCertPoolResponseItem | null>(null);

  if (isLoading) {
    return <Loading />;
  }

  if (!data) {
    navigate(ROUTES.NOT_FOUND);
    return <></>;
  }

  const qsForMedications = new URLSearchParams({
    province: provinceAssistance?.province?.key ?? '',
    program: data.card?.encId ?? '',
    assistanceLevel: String(provinceAssistance?.assistance?.level ?? '#ERROR'),
    generationPoint: CertificateGenerationPoint.ReferralPage,
    slug: slug ?? '',
    certPosition: data.certificatePosition,
    referral: 'true',
  });

  if (
    l10n(
      {
        en: data.programLogo?.url ?? '',
        fr: data.programFrLogo?.url ?? '',
      },
      locale,
    ) !== ''
  ) {
    qsForMedications.append(
      'customLogoUrl',
      l10n(
        {
          en: data.programLogo?.url ?? '',
          fr: data.programFrLogo?.url ?? '',
        },
        locale,
      ),
    );
  }

  if (
    l10n(
      {
        en: data.logo?.url ?? '',
        fr: data.frLogo?.url ?? '',
      },
      locale,
    ) !== ''
  ) {
    qsForMedications.append(
      'referralLogoUrl',
      l10n(
        {
          en: data.logo?.url ?? '',
          fr: data.frLogo?.url ?? '',
        },
        locale,
      ),
    );
    qsForMedications.append(
      'referralPartnerBackGroundUrl',
      l10n(
        {
          en: data.referalPartnerBackGround?.url ?? '',
          fr: data.frReferalPartnerBackGround?.url ?? '',
        },
        locale,
      ),
    );
  }

  return (
    <>
      <Helmet>
        <title>{l10n({ en: data.name, fr: data.frName }, locale)}</title>
      </Helmet>

      <DefaultLayout
        customLogoUrl={
          (data.programLogo?.url && locale === Language.English) ||
          (data.programFrLogo?.url && locale === Language.French)
            ? l10n(
                {
                  en: data.programLogo?.url ?? '',
                  fr: data.programFrLogo?.url ?? '',
                },
                locale,
              )
            : undefined
        }
      >
        <Box
          minHeight={{ sm: '680px' }}
          paddingBottom={{ xs: '60px' }}
          paddingLeft={{ sm: '50px' }}
          paddingRight={{ sm: '50px' }}
          sx={{
            backgroundImage: `url(/Homepage-banner-rxhelp-2000.jpg)`,
            backgroundSize: 'cover',
            backgroundPositionX: 'center',
            backgroundPositionY: 'top',
            py: 4,
          }}
        >
          <Container sx={{ py: 2 }}>
            <Box
              display={{ xs: 'flex', sm: 'inline-grid' }}
              marginRight={{ xs: '-16px' }}
              marginLeft={{ xs: '-16px', sm: '-32px' }}
              sx={{
                backgroundColor: 'rgba(0,0,0,0.4)',
                marginTop: '-32px',
                marginBottom: '-32px',
              }}
            >
              <Box sx={{ padding: '32px' }}>
                <Typography
                  variant="h1"
                  fontSize={{ xs: '2.25em', sm: '3.25em' }}
                  style={{
                    fontWeight: 'bold',
                    color: 'white',
                  }}
                >
                  {l10n(
                    {
                      en: data.name,
                      fr: data.frName,
                    },
                    locale,
                  )}
                </Typography>

                <Box sx={{ mb: 1 }} />

                {convertHTMLToString(
                  l10n(
                    {
                      en: data.card?.tagLine ?? '',
                      fr: data.card?.frTagLine ?? '',
                    },
                    locale,
                  ),
                ) && (
                  <div
                    style={{
                      fontSize: '1.5em',
                      paddingBottom: '1em',
                      color: 'white',
                    }}
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: l10n(
                        {
                          en: data.card?.tagLine ?? '',
                          fr: data.card?.frTagLine ?? '',
                        },
                        locale,
                      ),
                    }}
                  />
                )}

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={30}>
                  <ProvinceDiv>
                    <ProvinceAssistanceSelector
                      key={locale}
                      language={locale}
                      cardEncId={data.card?.encId ?? ''}
                      RenderInputProps={{
                        label: (
                          <FormattedMessage
                            id="components.PageLanding.select.provinceAssistanceLevel.label"
                            defaultMessage="Select Province"
                          />
                        ),
                      }}
                      size="small"
                      value={provinceAssistance}
                      onChange={(_, value) => {
                        setProvinceAssistance(value);
                      }}
                    />

                    <Button
                      sx={{ marginTop: '1rem' }}
                      style={buttonStyle}
                      variant="contained"
                      disabled={!provinceAssistance}
                      href={
                        data?.card?.pdfOptions === 'Upload' &&
                        data.customPdfFile
                          ? ''
                          : `${generatePath(ROUTES.WIZARD, {
                              lang: lang ?? 'en',
                            })}?${qsForMedications}`
                      }
                      onClick={() => {
                        if (
                          data?.card?.pdfOptions === 'Upload' &&
                          data.customPdfFile
                        ) {
                          openBase64NewTab(data.customPdfFile);
                        }
                      }}
                    >
                      <FormattedMessage
                        id="components.PageHome.getCard"
                        defaultMessage="Get Your Free Card"
                      />
                    </Button>
                    <Box sx={{ mt: 2, maxWidth: { xs: '100%' } }}>
                      <RxHelpCard
                        cert=""
                        group=""
                        layout={data.certificatePosition}
                        certificateTextColor={
                          data.card?.certificateTextColor ?? ''
                        }
                        imgUrl={l10n(
                          {
                            en: data.referalPartnerBackGround?.url ?? '',
                            fr: data.frReferalPartnerBackGround?.url ?? '',
                          },
                          locale,
                        )}
                        topImgUrl={l10n(
                          {
                            en: data.logo?.url ?? '',
                            fr: data.frLogo?.url ?? '',
                          },
                          locale,
                        )}
                      />
                    </Box>
                  </ProvinceDiv>
                </Stack>
              </Box>
            </Box>
          </Container>
        </Box>
        <PartnersDiv>
          <PartnersAfterDiv className="partners-after" />
          <Container sx={{ py: 2, paddingTop: '100px' }}>
            <Box sx={{ mb: 1 }} />

            <Typography variant="h1">
              <FormattedMessage
                id="components.PageReferralPartners.partners"
                defaultMessage="RxHelpONE Partners"
              />
            </Typography>

            <Box sx={{ mb: 1 }} />

            <div>
              <img
                alt="RxHelp"
                src={l10n(
                  {
                    en: data.logo?.url ?? '',
                    fr: data.frLogo?.url ?? '',
                  },
                  locale,
                )}
              />
            </div>

            <Box sx={{ mb: 1 }} />

            <Typography variant="h1">It&apos;s about Choice</Typography>

            <Box sx={{ mb: 1 }} />

            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: l10n(
                  {
                    en: data.description ?? '',
                    fr: data.frDescription ?? '',
                  },
                  locale,
                ),
              }}
            />
          </Container>
        </PartnersDiv>
      </DefaultLayout>
    </>
  );
}
