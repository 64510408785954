import React from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Alert, Box, Button, Container, Grid, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import { DefaultLayout } from '../../layouts/DefaultLayout';
import { Loading } from '../../components/Loading';
import { LogoItem, getEmployers } from '../../lib/api/getEmployers';
import { SupporterLogo } from '../../components/SupporterLogo';
import { QUERY } from '../../queryClient';
import { ROUTES } from '../../router';
import { l10n } from '../../Language';
import { useAppLocale } from '../../components/AppLocaleProvider';
import { PharmacyLocator } from '../../components/PharmacyLocator';

const buttonStyle = {
  height: 'fit-content',
  marginTop: '1rem',
  borderRadius: '100px',
  padding: '16px 42px',
  color: '#000000',
  textDecoration: 'none',
  fontFamily: 'Open Sans, sans-serif',
  fontWeight: 700,
  fontSize: '1.25em',
};

function byOrderASC(a: LogoItem, b: LogoItem) {
  return a.order - b.order;
}

export function PageEmployers() {
  const intl = useIntl();
  const { lang } = useParams();
  const [locale] = useAppLocale();
  const { data, isLoading, error } = useQuery([QUERY.PLAN], () =>
    getEmployers(),
  );

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'components.PageEmployers.meta.title',
            defaultMessage: 'Employers & Benefit Plans',
          })}
        </title>
      </Helmet>

      <DefaultLayout>
        <Container sx={{ py: 2 }}>
          {/* eslint-disable-next-line no-nested-ternary */}
          {isLoading ? (
            <Loading />
          ) : error || !data ? (
            <Alert severity="error">{String(error)}</Alert>
          ) : (
            <>
              <Box component="article">
                <div
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: l10n(
                      { en: data.content, fr: data.frContent },
                      locale,
                    ),
                  }}
                />
              </Box>

              <Box sx={{ mb: 4 }} />

              <Button
                style={buttonStyle}
                variant="contained"
                color="secondary"
                href={generatePath(ROUTES.CONTACT, { lang: lang ?? 'en' })}
              >
                <FormattedMessage
                  id="components.PageEmployers.button.contactUs"
                  defaultMessage="For more information contact us"
                />
              </Button>

              {data.logos.length > 0 && (
                <>
                  <Box sx={{ mb: 4 }} />

                  <Typography variant="h2">
                    <FormattedMessage
                      id="components.PageEmployers.supporters.header"
                      defaultMessage="Current program supporters"
                    />
                  </Typography>

                  <Box sx={{ mb: 2 }} />

                  <Grid container spacing={2}>
                    {data?.logos.sort(byOrderASC).map((item) => (
                      <Grid item key={item.encId} xs={12} sm={6} lg={3}>
                        <SupporterLogo url={item.url} />
                      </Grid>
                    ))}
                  </Grid>
                </>
              )}
            </>
          )}
        </Container>

        <PharmacyLocator />
      </DefaultLayout>
    </>
  );
}
