import React from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Box,
  Breadcrumbs,
  Container,
  Link,
  Paper,
  Typography,
} from '@mui/material';
import { DefaultLayout } from '../../layouts/DefaultLayout';
import { CompanyRegistrationForm } from '../../components/CompanyRegistrationForm';
import { ROUTES } from '../../router';

export function PagePharmacyLocatorRegistration() {
  const intl = useIntl();
  const { lang } = useParams();

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'components.PagePharmacyLocatorRegistration.meta.title',
            defaultMessage: 'Pharmacy Locator — Company Registration',
          })}
        </title>
      </Helmet>

      <DefaultLayout>
        <Container sx={{ py: 2 }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              color="inherit"
              href={generatePath(ROUTES.HOME, { lang: lang ?? 'en' })}
            >
              <FormattedMessage
                id="components.PagePharmacyLocatorRegistration.breadcrumbs.home"
                defaultMessage="RxHelp"
              />
            </Link>
            <Link
              underline="hover"
              color="inherit"
              href={generatePath(ROUTES.PHARMACY_LOCATOR, {
                lang: lang ?? 'en',
              })}
            >
              <FormattedMessage
                id="components.PagePharmacyLocatorRegistration.breadcrumbs.pharmacyLocator"
                defaultMessage="Pharmacy Locator"
              />
            </Link>
            <Typography color="text.primary">
              <FormattedMessage
                id="components.PagePharmacyLocatorRegistration.breadcrumbs.companyRegistration"
                defaultMessage="Company Registration"
              />
            </Typography>
          </Breadcrumbs>

          <Box sx={{ mb: 2 }} />

          <Typography>
            <FormattedMessage
              id="components.PagePharmacyLocatorRegistration.subtitle"
              defaultMessage="Please fill in the information below in order to register:"
            />
          </Typography>

          <Box sx={{ mb: 2 }} />

          <Paper elevation={2} sx={{ p: 2 }}>
            <CompanyRegistrationForm />
          </Paper>
        </Container>
      </DefaultLayout>
    </>
  );
}
