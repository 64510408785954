import { Box, IconButton, IconButtonProps, styled } from '@mui/material';
import React from 'react';

export const ALL_VARIANTS = 'ALL';
const EN_ALPHABET = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
] as const;

interface ToggleIconButtonProps extends IconButtonProps {
  checked: boolean;
}
const ToggleIconButton = styled(IconButton)<ToggleIconButtonProps>(
  ({ theme, checked }) => ({
    color: checked ? theme.palette.secondary.main : undefined,
    width: 42,
    height: 42,
  }),
);

interface AlphabetSelectorProps {
  availableChars?: string[];
  value: string;
  onChange: (value: string) => void;
}

export function AlphabetSelector({
  availableChars,
  value,
  onChange,
}: AlphabetSelectorProps) {
  return (
    <Box>
      <ToggleIconButton
        checked={value === ALL_VARIANTS}
        onClick={() => onChange(ALL_VARIANTS)}
      >
        {ALL_VARIANTS}
      </ToggleIconButton>

      {EN_ALPHABET.map((char) => (
        <ToggleIconButton
          key={char}
          checked={value === char}
          onClick={() => onChange(char)}
          disabled={availableChars ? !availableChars.includes(char) : undefined}
        >
          {char}
        </ToggleIconButton>
      ))}
    </Box>
  );
}
