import { httpClient } from '../httpClient';
import type { DateTime, HTML, PositiveInteger } from '../types';

interface FAQItem {
  encId: string;
  title: string;
  frTitle: string;
  text: HTML;
  frText: HTML;
  displayOrder: number;
  createDate: DateTime;
  modifiedDate: DateTime;
}

interface GetFAQResponse {
  pageNumber: PositiveInteger;
  pageSize: PositiveInteger;
  totalPages: PositiveInteger;
  totalRecords: number;
  items: Array<FAQItem>;
}

export interface GetFAQParams {
  page?: PositiveInteger;
  pageSize?: PositiveInteger;
  orderBy?: `${keyof FAQItem}:${'asc' | 'desc'}`;
}

export function getFAQ(params?: GetFAQParams) {
  return httpClient.get<GetFAQParams, GetFAQResponse>('/faqs', params);
}
