import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Box, Container, Grid, Paper, Typography } from '@mui/material';
import { Helmet } from 'react-helmet';
import { DefaultLayout } from '../../layouts/DefaultLayout';
import { ContactUsForm } from '../../components/ContactUsForm';

export function PageContactUs() {
  const intl = useIntl();

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'components.PageContactUs.meta.title',
            defaultMessage: 'Contact Us',
          })}
        </title>
      </Helmet>

      <DefaultLayout>
        <Container sx={{ py: 2 }}>
          <Typography variant="h1">
            <FormattedMessage
              id="components.PageContactUs.header"
              defaultMessage="Contact Us"
            />
          </Typography>

          <Box sx={{ mb: 2 }} />

          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="h2">
                <FormattedMessage
                  id="components.PageContactUs.subheader"
                  defaultMessage="1-866-RxHelp4"
                />
              </Typography>

              <Typography variant="body1">
                <FormattedMessage
                  id="components.PageContactUs.contact-info.line1"
                  defaultMessage="Cameron Stewart LifeScience"
                />
              </Typography>

              <Typography variant="body1">
                <FormattedMessage
                  id="components.PageContactUs.contact-info.line2"
                  defaultMessage="Canada Inc."
                />
              </Typography>

              <Typography variant="body1">
                <FormattedMessage
                  id="components.PageContactUs.contact-info.line3"
                  defaultMessage="3470 Superior Court"
                />
              </Typography>

              <Typography variant="body1">
                <FormattedMessage
                  id="components.PageContactUs.contact-info.line4"
                  defaultMessage="Oakville, ON  L6L 0C4"
                />
              </Typography>

              <Typography variant="body1">
                <FormattedMessage
                  id="components.PageContactUs.contact-info.line5"
                  defaultMessage="Phone: 416-674-0803"
                />
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <Paper elevation={2} sx={{ p: 2 }}>
                <ContactUsForm />
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </DefaultLayout>
    </>
  );
}
