import { httpClient } from '../httpClient';

export enum ContactUsReason {
  TechnicalIssue = 'technicalQuestion',
  ProductQuestion = 'productQuestion',
  ProgramSpecificQuestion = 'programSpecificQuestion',
  CardDownload = 'cardDownload',
  GeneralInquiry = 'generalInquiry',
  Other = 'other',
}
export interface SendContactUsData {
  name: string;
  email: string;
  phone: string;
  reason: ContactUsReason;
  message: string;
  acceptTerms: boolean;
  isVerified: boolean;
  verifyToken: string;
}

export function sendContactUs(payload: SendContactUsData) {
  return httpClient.post('/SupportMessages', payload);
}
