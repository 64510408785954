import { httpClient } from '../httpClient';
import { DateTime, PositiveInteger } from '../types';

export enum SearchBy {
  PostalCode = 'postalCode',
  City = 'city',
  PharmacyName = 'pharmacyName',
}

interface SearchPharmacyParams {
  searchBy: SearchBy;
  postalCode?: string;
  within?: string;
  city?: string;
  province?: string;
  name?: string;
  page: PositiveInteger;
  pageSize: PositiveInteger;
}

interface SearchPharmacyInternalParams extends SearchPharmacyParams {
  status: 'active' | 'inactive';
}

export interface SearchPharmacyItem {
  encId: string;
  role: 'Pharmacist';
  licenseNumber: string;
  name: string;
  language: 'En' | 'Fr';
  salutations: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  fax: string;
  address: string;
  city: string;
  province: string;
  postalCode: string;
  longitude: number;
  latitude: number;
  status: 'active' | 'inactive';
  modifiedDate: DateTime;
}

interface SearchPharmacyResponse {
  chars: string[];
  pageNumber: PositiveInteger;
  pageSize: PositiveInteger;
  totalPages: number;
  totalRecords: number;
  items: SearchPharmacyItem[];
}

export function searchPharmacy(
  params: SearchPharmacyParams,
): Promise<SearchPharmacyResponse> {
  return httpClient.get<SearchPharmacyInternalParams, SearchPharmacyResponse>(
    '/pharmacies',
    {
      status: 'active',
      ...params,
    },
  );
}
