import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useQuery } from 'react-query';
import { Box, Container, Grid, Stack, Typography } from '@mui/material';
import { ThumbUp as ThumbUpIcon } from '@mui/icons-material';
import {
  FetchProgramSupportersParams,
  fetchProgramSupporters,
} from '../../lib/api/fetchProgramSupporters';
import { QUERY } from '../../queryClient';

const queryParams: FetchProgramSupportersParams = {
  page: 1,
  pageSize: 10000,
  orderBy: 'order:asc',
};

export function HomeProgramSupporters() {
  const { isLoading, data } = useQuery([QUERY.SUPPORTERS, queryParams], () =>
    fetchProgramSupporters(queryParams),
  );

  if (isLoading || !data || data.items.length <= 0) {
    return null;
  }

  return (
    <Box sx={{ backgroundColor: (t) => t.palette.grey[100], py: 6 }}>
      <Container>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 0, sm: 1 }}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="h2"
            minWidth={{ sm: '300px' }}
            paddingBottom={{ xs: '40px' }}
            style={{ fontWeight: 'bold' }}
          >
            <FormattedMessage
              id="components.PageHome.title.programSupported"
              defaultMessage="Program Supporters"
            />
          </Typography>

          <Grid container spacing={2} style={{ alignItems: 'center' }}>
            {data.items.map((item) => (
              <Grid
                item
                key={item.encId}
                xs={12}
                sm={12 / Math.min(data.items.length, 3)}
                lg={12 / Math.min(data.items.length, 5)}
                style={{ padding: 0, margin: 10 }}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 0,
                  }}
                >
                  <Box
                    component="img"
                    alt={item.name}
                    src={item.url}
                    sx={{ maxWidth: '100%' }}
                  />
                </Box>
              </Grid>
            ))}
          </Grid>
        </Stack>
      </Container>
    </Box>
  );
}
