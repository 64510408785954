import { Language } from '../../Language';
import { httpClient } from '../httpClient';
import type { DateTime } from '../types';
import { fetchProvinces } from './fetchProvinces';

interface Cert {
  encId: string;
  createDate: DateTime;
  modifiedDate: DateTime;
  province?: string;
  assistances: { encId: string; level: number }[];
  language: 'En' | 'Fr';
  groupCode: number;
  start: number;
  end: number;
  threshold?: number;
  status: 'Active' | 'Inactive';
}

export interface FetchProgramCertPoolResponseItem {
  key: string;
  province?: Awaited<ReturnType<typeof fetchProvinces>>[number];
  assistance?: Cert['assistances'][number];
}

export interface FetchProgramCertPoolParams {
  language: Language;
}

export function fetchProgramCertPool(
  encId: string,
  language: Language,
): Promise<FetchProgramCertPoolResponseItem[]> {
  return Promise.all([
    fetchProvinces({ isActive: false, language }),
    httpClient.get<FetchProgramCertPoolParams, Cert[]>(
      `/cards/${encId}/certificatePools`,
      { language },
    ),
  ]).then(([provinces, pools]) => {
    const provinceAssistance: FetchProgramCertPoolResponseItem[] = [];
    pools?.forEach((pool) => {
      if (pool.status !== 'Active') {
        return;
      }
      const curPr = provinces.find((pr) => pr.key === pool.province);

      if (pool.assistances && pool.assistances.length > 0) {
        pool.assistances.forEach((ass) => {
          // Should we filter it? it seems a bug
          // const isPresent = provinceAssistance.some(
          //   (item) =>
          //     item.province === (curPr?.key ?? '') &&
          //     item.assistanceLevelPercentage === ass.level,
          // );
          // if (isPresent) {
          //   return;
          // }
          provinceAssistance.push({
            key: `${pool.encId}-${ass.encId}`,
            province: curPr,
            assistance: ass,
          });
        });
      } else {
        provinceAssistance.push({
          key: `${pool.encId}`,
          province: curPr,
        });
      }
    });
    return provinceAssistance;
  });
}
