import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, Button, Container, Typography } from '@mui/material';
import { LocationOn as LocationOnIcon } from '@mui/icons-material';
import { generatePath, useParams } from 'react-router-dom';
import { ROUTES } from '../../router';

const buttonStyle = {
  height: 'fit-content',
  marginTop: '1rem',
  borderRadius: '100px',
  padding: '16px 42px',
  color: '#000000',
  textDecoration: 'none',
  fontFamily: 'Open Sans, sans-serif',
  fontWeight: 700,
  fontSize: '1.25em',
};

export function PharmacyLocator() {
  const { lang } = useParams();
  return (
    <Box
      sx={{
        backgroundColor: (t) => t.palette.grey[100],
        backgroundImage: 'url(/map-bg.jpg)',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        py: 10,
      }}
    >
      <Container sx={{ textAlign: 'center' }}>
        <Box>
          <LocationOnIcon color="primary" sx={{ fontSize: 60 }} />
        </Box>
        <Typography variant="h2">
          <FormattedMessage
            id="components.PharmacyLocator.title"
            defaultMessage="Pharmacy Locator"
          />
        </Typography>
        <Typography variant="body1">
          <FormattedMessage
            id="components.PharmacyLocator.subtitle"
            defaultMessage="Select Province above to see all available medications"
          />
        </Typography>

        <Box sx={{ mb: 2 }} />

        <Button
          style={buttonStyle}
          href={generatePath(ROUTES.PHARMACY_LOCATOR, { lang: lang ?? 'en' })}
          variant="contained"
          color="secondary"
        >
          <FormattedMessage
            id="components.PharmacyLocator.button.findPharmacy"
            defaultMessage="Find a Pharmacy"
          />
        </Button>
      </Container>
    </Box>
  );
}
