import React from 'react';
import { useQuery } from 'react-query';
import {
  TextField,
  TextFieldProps,
  Autocomplete,
  AutocompleteProps,
} from '@mui/material';
import { QUERY } from '../../queryClient';
import { Language, l10n } from '../../Language';
import {
  FetchProgramCertPoolParams,
  FetchProgramCertPoolResponseItem,
  fetchProgramCertPool,
} from '../../lib/api/fetchProgramCertPool';

type OptionType = Awaited<ReturnType<typeof fetchProgramCertPool>>[number];

type ProvinceAssistanceSelectorProps = Omit<
  AutocompleteProps<OptionType, false, false, false>,
  'renderInput' | 'options'
> & {
  cardEncId: string;
  language: Language;
  changeLabelNoProvince?: boolean;
  RenderInputProps?: Omit<
    TextFieldProps,
    'value' | 'defaultValue' | 'onChange' | 'InputProps'
  >;
};

export function ProvinceAssistanceSelector({
  cardEncId,
  RenderInputProps,
  changeLabelNoProvince,
  language,
  ...props
}: ProvinceAssistanceSelectorProps) {
  const { data, isLoading, error } = useQuery(
    [QUERY.CERT_POOL, cardEncId],
    () => fetchProgramCertPool(cardEncId, language),
  );

  function compareFn(
    a: FetchProgramCertPoolResponseItem,
    b: FetchProgramCertPoolResponseItem,
  ): number {
    return l10n(
      {
        en: a.assistance
          ? `${a.province?.province ?? 'National'} — ${a.assistance.level}%`
          : `${a.province?.province ?? 'National'}`,
        fr: a.assistance
          ? `${a.province?.frProvince ?? 'National'} — ${a.assistance.level}%`
          : `${a.province?.frProvince ?? 'National'}`,
      },
      language,
    ) >
      l10n(
        {
          en: b.assistance
            ? `${b.province?.province ?? 'National'} — ${b.assistance.level}%`
            : `${b.province?.province ?? 'National'}`,
          fr: b.assistance
            ? `${b.province?.frProvince ?? 'National'} — ${b.assistance.level}%`
            : `${b.province?.frProvince ?? 'National'}`,
        },
        language,
      )
      ? 1
      : -1;
  }

  return (
    <Autocomplete
      {...props}
      options={data?.toSorted(compareFn) ?? []}
      getOptionLabel={(option) =>
        l10n(
          {
            en: option.assistance
              ? `${option.province?.province ?? 'National'} — ${
                  option.assistance.level
                }%`
              : `${option.province?.province ?? 'National'}`,
            fr: option.assistance
              ? `${option.province?.frProvince ?? 'National'} — ${
                  option.assistance.level
                }%`
              : `${option.province?.frProvince ?? 'National'}`,
          },
          language,
        )
      }
      renderInput={(params) => (
        <TextField
          {...params}
          {...RenderInputProps}
          sx={{ minWidth: 220 }}
          key={`${params.id} ${language}`}
          disabled={isLoading || !!error}
          error={!!error}
          helperText={error ? String(error) : undefined}
          label={
            // eslint-disable-next-line no-nested-ternary
            changeLabelNoProvince
              ? // eslint-disable-next-line no-nested-ternary
                data && data.length > 0
                ? data.findIndex((d) => d.province?.province) > -1
                  ? l10n(
                      {
                        en: 'Select Province',
                        fr: 'Sélectionner une province',
                      },
                      language,
                    )
                  : l10n(
                      {
                        en: 'Select Assistance Level',
                        fr: `Sélectionner un niveau d'assistance`,
                      },
                      language,
                    )
                : RenderInputProps?.label
              : RenderInputProps?.label
          }
        />
      )}
    />
  );
}
