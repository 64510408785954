import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Autocomplete, InputAdornment, TextField } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import { useClientSiteIndex } from './useClientSiteIndex';

const WhiteBackgroundText = styled.div`
  .MuiInputBase-root {
    background-color: white;
  }
`;

export function SiteSearchField() {
  const navigate = useNavigate();
  const clientSiteIndex = useClientSiteIndex();

  return (
    <Autocomplete
      freeSolo
      disableClearable
      options={clientSiteIndex}
      onChange={(_, value) => {
        if (typeof value === 'string') {
          return;
        }
        navigate(value.url);
      }}
      renderInput={(params) => (
        <>
          <WhiteBackgroundText>
            <TextField
              {...params}
              sx={{ width: 240 }}
              label={
                <FormattedMessage
                  id="components.SiteSearchField.label"
                  defaultMessage="Search"
                />
              }
              InputProps={{
                ...params.InputProps,
                type: 'search',
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              size="small"
            />
          </WhiteBackgroundText>
        </>
      )}
    />
  );
}
