import React, { useState } from 'react';
import { useQuery } from 'react-query';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Container,
  Pagination,
  Typography,
} from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { FormattedMessage, useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import { DefaultLayout } from '../../layouts/DefaultLayout';
import { Loading } from '../../components/Loading';
import { getFAQ, GetFAQParams } from '../../lib/api/getFAQ';
import { QUERY } from '../../queryClient';
import { l10n } from '../../Language';
import { useAppLocale } from '../../components/AppLocaleProvider';
import { PharmacyLocator } from '../../components/PharmacyLocator';

const pageSize = 10;

export function PageFAQ() {
  const intl = useIntl();
  const [locale] = useAppLocale();
  const [page, setPage] = useState(1);
  const queryParams: GetFAQParams = {
    page,
    pageSize,
    orderBy: 'displayOrder:asc',
  } as const;
  const [expanded, setExpanded] = React.useState<string | null>(null);
  const { data, isLoading, error } = useQuery([QUERY.FAQ, queryParams], () =>
    getFAQ(queryParams),
  );

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'components.PageFAQ.meta.title',
            defaultMessage: 'FAQ',
          })}
        </title>
      </Helmet>

      <DefaultLayout>
        <Container sx={{ py: 2 }}>
          {/* eslint-disable-next-line no-nested-ternary */}
          {isLoading ? (
            <Loading />
          ) : error ? (
            <Alert severity="error">{String(error)}</Alert>
          ) : (
            <>
              <Typography variant="h1" component="div">
                <FormattedMessage
                  id="components.PageFAQ.header"
                  defaultMessage="FAQs"
                />
              </Typography>

              <Box sx={{ mb: 2 }} />

              {data?.items.map((item) => (
                <Accordion
                  key={item.encId}
                  expanded={expanded === item.encId}
                  onChange={(_, newExpanded) =>
                    setExpanded(newExpanded ? item.encId : null)
                  }
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography fontWeight="bold">
                      {l10n({ en: item.title, fr: item.frTitle }, locale)}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: l10n(
                          { en: item.text, fr: item.frText },
                          locale,
                        ),
                      }}
                    />
                  </AccordionDetails>
                </Accordion>
              ))}

              {(data?.totalPages ?? 1) > 1 && (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                  <Pagination
                    size="large"
                    count={data?.totalPages ?? 1}
                    page={page}
                    onChange={(_, value) => setPage(value)}
                  />
                </Box>
              )}
            </>
          )}
        </Container>

        <PharmacyLocator />
      </DefaultLayout>
    </>
  );
}
