import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { Controller, useForm } from 'react-hook-form';
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Reaptcha from 'reaptcha';
import { IMaskInput } from 'react-imask';
import {
  CompanyType,
  RegisterCompanyData,
  Salutations,
  registerCompany,
} from '../../lib/api/registerCompany';
import { Language } from '../../Language';
import { languages } from '../LanguageSelector/languages.translations';
import { companyType } from './companyType';
import { ProvinceSelector } from '../ProvinceSelector';
import { salutations } from './salutations';
import { useAppLocale } from '../AppLocaleProvider';
import { env } from '../../app/.shared/settings/env';

const POSTAL_CODE_FORMAT =
  /^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ] \d[ABCEGHJKLMNPRSTVWXYZ]\d$/;
const PHONE_NUMBER_FORMAT = /\d{3}-\d{3}-\d{4}/;

const schema = yup.object().shape({
  type: yup.string().required(),
  licenseNumber: yup
    .string()
    .required()
    .matches(
      /^\d{4} \d{4} \d{4}$/,
      'licenseNumber should be format of XXXX XXXX XXXX',
    ),
  name: yup.string().required(),
  language: yup.string().required().oneOf(Object.values(Language)),
  salutations: yup.string().required().oneOf(Object.values(Salutations)),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  phoneNumber: yup
    .string()
    .required()
    .matches(
      PHONE_NUMBER_FORMAT,
      'phoneNumber should match format NPA-NXX-XXXX',
    ),
  address: yup.string().required(),
  address2: yup.string(),
  city: yup.string().required(),
  province: yup.string().required(),
  postalCode: yup
    .string()
    .required()
    .uppercase()
    .matches(POSTAL_CODE_FORMAT, 'postalCode should match format ANA NAN'),
  email: yup
    .string()
    .email()
    .required()
    .matches(
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+(?:[A-Za-z]{2,6})\b/,
      'Email must be in a proper format.',
    ),
  confirmEmail: yup
    .string()
    .email()
    .required()
    .oneOf([yup.ref('email')], 'confirmEmail and email should match'),
  termsAccepted: yup.boolean().required(),
  marketingMessages: yup.boolean(),
  isVerified: yup.boolean().isTrue('Recaptcha is not valid'),
});

export function CompanyRegistrationForm() {
  const intl = useIntl();
  const [locale] = useAppLocale();
  let captchaRef: Reaptcha | null = null;

  const {
    control,
    handleSubmit,
    reset,
    setError,
    clearErrors,
    setValue,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      type: CompanyType.Pharmacist,
      licenseNumber: '',
      name: '',
      language: Language.English,
      salutations: Salutations.Mr,
      firstName: '',
      lastName: '',
      phoneNumber: '',
      address: '',
      address2: '',
      city: '',
      province: '',
      postalCode: '',
      email: '',
      confirmEmail: '',
      termsAccepted: false,
      marketingMessages: false,
      isVerified: false,
      verifyToken: '',
    },
  });

  const { mutate } = useMutation<unknown, unknown, RegisterCompanyData>(
    ['CompanyRegistrationForm'],
    (payload) => registerCompany(payload),
    {
      onSuccess() {
        toast.success('Company successfully created');
        captchaRef?.reset();
        captchaRef = null;
        reset();
      },
      onError(e: any) {
        captchaRef?.reset();
        captchaRef = null;
        if (e.response.data.description) {
          toast.error(e.response.data.description);
        } else {
          toast.error('Cannot create company');
        }
      },
    },
  );

  return (
    <Box
      component="form"
      onSubmit={handleSubmit((data, e) => {
        if (!data.isVerified) {
          setError('isVerified', {
            type: 'custom',
            message: 'Recaptcha is not valid',
          });
        } else if (!data.verifyToken || data.verifyToken === '') {
          setError('isVerified', {
            type: 'custom',
            message: 'Recaptcha is not valid',
          });
        } else {
          clearErrors('isVerified');
          e?.preventDefault();
          mutate(data as RegisterCompanyData);
        }
      })}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Controller
            control={control}
            name="type"
            render={({ field, fieldState }) => (
              <TextField
                select
                fullWidth
                required
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                label={
                  <FormattedMessage
                    id="components.CompanyRegistrationForm.field.type"
                    defaultMessage="Please indicate which applies to you"
                  />
                }
                {...field}
              >
                {Object.values(CompanyType).map((item) => (
                  <MenuItem key={item} value={item}>
                    {intl.formatMessage(companyType[`companyType.${item}`])}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            control={control}
            name="licenseNumber"
            render={({ field, fieldState }) => (
              <TextField
                InputProps={{
                  inputComponent: IMaskInput,
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                inputProps={{
                  mask: '0000 0000 0000',
                }}
                fullWidth
                required
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                label={
                  <FormattedMessage
                    id="components.CompanyRegistrationForm.field.licenseNumber"
                    defaultMessage="License Number"
                  />
                }
                {...field}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            control={control}
            name="name"
            render={({ field, fieldState }) => (
              <TextField
                fullWidth
                required
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                label={
                  <FormattedMessage
                    id="components.CompanyRegistrationForm.field.companyName"
                    defaultMessage="Pharmacy/Company Name"
                  />
                }
                {...field}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            control={control}
            name="language"
            render={({ field, fieldState }) => (
              <TextField
                select
                fullWidth
                required
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                label={
                  <FormattedMessage
                    id="components.CompanyRegistrationForm.field.language"
                    defaultMessage="Language"
                  />
                }
                {...field}
              >
                {Object.values(Language).map((item) => (
                  <MenuItem key={item} value={item}>
                    {intl.formatMessage(languages[`Language.${item}`])}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            control={control}
            name="salutations"
            render={({ field, fieldState }) => (
              <TextField
                select
                fullWidth
                required
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                label={
                  <FormattedMessage
                    id="components.CompanyRegistrationForm.field.salutations"
                    defaultMessage="Salutations"
                  />
                }
                {...field}
              >
                {Object.values(Salutations).map((item) => (
                  <MenuItem key={item} value={item}>
                    {intl.formatMessage(salutations[`salutations.${item}`])}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6} display={{ xs: 'none', sm: 'block' }} />

        <Grid item xs={12} sm={6}>
          <Controller
            control={control}
            name="firstName"
            render={({ field, fieldState }) => (
              <TextField
                fullWidth
                required
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                label={
                  <FormattedMessage
                    id="components.CompanyRegistrationForm.field.firstName"
                    defaultMessage="First Name"
                  />
                }
                {...field}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            control={control}
            name="lastName"
            render={({ field, fieldState }) => (
              <TextField
                fullWidth
                required
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                label={
                  <FormattedMessage
                    id="components.CompanyRegistrationForm.field.lastName"
                    defaultMessage="Last Name"
                  />
                }
                {...field}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            control={control}
            name="phoneNumber"
            render={({ field, fieldState }) => (
              <TextField
                InputProps={{
                  inputComponent: IMaskInput,
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                inputProps={{
                  mask: '000-000-0000',
                }}
                fullWidth
                required
                type="tel"
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                label={
                  <FormattedMessage
                    id="components.CompanyRegistrationForm.field.phoneNumber"
                    defaultMessage="Phone Number"
                  />
                }
                {...field}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6} display={{ xs: 'none', sm: 'block' }} />

        <Grid item xs={12} sm={8}>
          <Controller
            control={control}
            name="address"
            render={({ field, fieldState }) => (
              <TextField
                fullWidth
                required
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                label={
                  <FormattedMessage
                    id="components.CompanyRegistrationForm.field.streetAddress1"
                    defaultMessage="Street Address 1"
                  />
                }
                {...field}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <Controller
            control={control}
            name="address2"
            render={({ field, fieldState }) => (
              <TextField
                fullWidth
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                label={
                  <FormattedMessage
                    id="components.CompanyRegistrationForm.field.streetAddress2"
                    defaultMessage="Street Address 2"
                  />
                }
                {...field}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <Controller
            control={control}
            name="city"
            render={({ field, fieldState }) => (
              <TextField
                fullWidth
                required
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                label={
                  <FormattedMessage
                    id="components.CompanyRegistrationForm.field.city"
                    defaultMessage="City"
                  />
                }
                {...field}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={5}>
          <Controller
            control={control}
            name="province"
            render={({ field, fieldState }) => (
              <ProvinceSelector
                language={locale}
                fullWidth
                required
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                label={
                  <FormattedMessage
                    id="components.CompanyRegistrationForm.field.province"
                    defaultMessage="Province"
                  />
                }
                {...field}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <Controller
            control={control}
            name="postalCode"
            render={({ field, fieldState }) => (
              <TextField
                InputProps={{
                  inputComponent: IMaskInput,
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                inputProps={{
                  mask: 'a0a 0a0',
                }}
                fullWidth
                required
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                label={
                  <FormattedMessage
                    id="components.CompanyRegistrationForm.field.postalCode"
                    defaultMessage="Postal Code"
                  />
                }
                {...field}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            control={control}
            name="email"
            render={({ field, fieldState }) => (
              <TextField
                fullWidth
                required
                type="email"
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                label={
                  <FormattedMessage
                    id="components.CompanyRegistrationForm.field.email"
                    defaultMessage="Email"
                  />
                }
                {...field}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            control={control}
            name="confirmEmail"
            render={({ field, fieldState }) => (
              <TextField
                fullWidth
                required
                type="email"
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                label={
                  <FormattedMessage
                    id="components.CompanyRegistrationForm.field.confirmEmail"
                    defaultMessage="Confirm Email"
                  />
                }
                {...field}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            control={control}
            name="termsAccepted"
            render={({ field, fieldState }) => (
              <>
                <FormControlLabel
                  label={
                    <FormattedMessage
                      id="components.CompanyRegistrationForm.field.termsAccepted"
                      defaultMessage="By checking this box I confirm that I have read, understood and consented to the RxHelp.ca Privacy Policy and Terms and Conditions. I also acknowledge that the information contained in these documents applies to ALL existing and future Patient Programs featured on this site."
                    />
                  }
                  control={<Checkbox required {...field} />}
                />
                {fieldState.error && (
                  <FormHelperText error={!!fieldState.error}>
                    {fieldState.error?.message}
                  </FormHelperText>
                )}
              </>
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            control={control}
            name="marketingMessages"
            render={({ field, fieldState }) => (
              <>
                <FormControlLabel
                  label={
                    <FormattedMessage
                      id="components.CompanyRegistrationForm.field.marketingMessages"
                      defaultMessage="Check if you would like to be emailed when new products are added to RxHelp.ca. You may withdraw your consent at any time."
                    />
                  }
                  control={<Checkbox {...field} />}
                />
                {fieldState.error && (
                  <FormHelperText error={!!fieldState.error}>
                    {fieldState.error?.message}
                  </FormHelperText>
                )}
              </>
            )}
          />
        </Grid>

        <Box sx={{ mb: 2, paddingLeft: 2 }}>
          <Reaptcha
            sitekey={env.GOOGLE_RECAPTCHA_PUBLIC_KEY}
            ref={(e) => {
              if (e !== null) {
                captchaRef = e;
              }
            }}
            onVerify={(recaptchaResponse) => {
              setValue('isVerified', true, { shouldValidate: true });
              clearErrors('isVerified');
              setValue('verifyToken', recaptchaResponse, {
                shouldValidate: true,
              });
            }}
          />
          {errors.isVerified && (
            <p style={{ color: 'red' }}>{errors.isVerified.message}</p>
          )}
        </Box>

        <Box sx={{ mb: 2 }} />

        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isSubmitting}
              sx={{ borderRadius: 100 }}
            >
              <FormattedMessage
                id="components.CompanyRegistrationForm.button.submit"
                defaultMessage="Submit"
              />
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
