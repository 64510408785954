import { Language } from '../../Language';
import { httpClient } from '../httpClient';

export enum CompanyType {
  Pharmacist = 'pharmacist',
  Physician = 'physician',
}

export enum Salutations {
  Miss = 'miss',
  Attn = 'attn',
  Dr = 'dr',
  Mr = 'mr',
  Mrs = 'mrs',
  Ms = 'ms',
}

export interface RegisterCompanyData {
  type: CompanyType;
  licenseNumber: string;
  name: string;
  language: Language;
  salutations: Salutations;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  address: string;
  address2: string;
  city: string;
  province: string;
  postalCode: string;
  email: string;
  confirmEmail: string;
  termsAccepted: boolean;
  marketingMessages: boolean;
  isVerified: boolean;
  verifyToken: string;
}

interface RegisterCompanyInternalData extends RegisterCompanyData {
  fax: string;
}

export function registerCompany(payload: RegisterCompanyData) {
  return httpClient.post<RegisterCompanyInternalData, void>('/pharmacies', {
    ...payload,
    fax: payload.phoneNumber,
  });
}
