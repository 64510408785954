import { httpClient } from '../httpClient';
import type { DateTime, HTML, PositiveInteger } from '../types';

enum NewsStatus {
  Active = 'active',
  Inactive = 'inactive',
}

interface GetNewsListItem {
  encId: string;
  status: NewsStatus;
  title: string;
  frTitle: string;
  content: HTML;
  frContent: HTML;
  createDate: DateTime;
  modifiedDate: DateTime;
  startDate: DateTime;
  endDate?: DateTime;
  isPermanent: boolean;
}

interface GetNewsListResponse {
  pageNumber: PositiveInteger;
  pageSize: PositiveInteger;
  totalPages: PositiveInteger;
  totalRecords: number;
  items: Array<GetNewsListItem>;
}

export interface GetNewsListParams {
  page?: PositiveInteger;
  pageSize?: PositiveInteger;
  orderBy?: `${keyof GetNewsListItem}:${'asc' | 'desc'}`;
}

interface GetNewsListInternalParams extends GetNewsListParams {
  start: DateTime;
  end: DateTime;
  status: NewsStatus;
}

export function getNewsList(params?: GetNewsListParams) {
  const now = new Date();
  const start = now.toISOString();

  const endDate = new Date(now);
  endDate.setHours(23, 59, 59, 999);
  const end = endDate.toISOString();

  return httpClient.get<GetNewsListInternalParams, GetNewsListResponse>(
    '/news',
    { start, end, status: NewsStatus.Active, ...params },
  );
}
