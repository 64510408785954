import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { FormattedMessage } from 'react-intl';
import { Box, Button, Container, Grid, Stack, Typography } from '@mui/material';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import ReactPlayer from 'react-player';
import { DefaultLayout } from '../../layouts/DefaultLayout';
import { l10n, Language } from '../../Language';
import { useAppLocale } from '../../components/AppLocaleProvider';
import { QUERY } from '../../queryClient';
import { Loading } from '../../components/Loading';
import { ROUTES } from '../../router';
import { getLandingPageBySlug } from '../../lib/api/getLandingPageBySlug';
import { ProvinceAssistanceSelector } from '../../components/ProvinceAssistanceSelector';
import { RxHelpCard } from '../../components/RxHelpCard';
import { FetchProgramCertPoolResponseItem } from '../../lib/api/fetchProgramCertPool';
import { ListItem } from '../PageWizard/ListItem';
import { LandingItem, ProductInfo } from '../../lib/types';
import {
  convertHTMLToString,
  convertHTMLToText,
} from '../../app/.shared/utils/convertUtil';
import { CertificateGenerationPoint } from '../../CertificateGenerationPoint';

const ProvinceDiv = styled.div`
  display: inline-grid;

  .MuiButton-root.Mui-disabled {
    background-color: rgba(243, 150, 0, 0.12);
  }

  .MuiButton-root {
    background-color: rgba(243, 150, 0);
  }

  .MuiInputBase-root {
    background-color: white;
  }
`;

const buttonStyle = {
  height: 'fit-content',
  marginTop: '1rem',
  borderRadius: '100px',
  padding: '16px 42px',
  color: '#ffffff',
  textDecoration: 'none',
  fontFamily: 'Open Sans, sans-serif',
  fontWeight: 700,
  fontSize: '1.25em',
};

const ImportantInfoDiv = styled.div`
  z-index: 1;
  position: relative;
  padding-bottom: 50px;

  &:before {
    height: 23px;
    transform: skewY(-4.8deg);
    top: -58px;
    content: '';
    display: block;
    position: relative;
    background-color: #f39600;
    margin-bottom: -100px;
  }
`;

const ImportantInfoAfterDiv = styled.div`
  z-index: 1;
  position: relative;
  padding-bottom: 50px;

  &:before {
    height: 24px;
    transform: skewY(-4deg);
    top: 23px;
    content: '';
    display: block;
    position: relative;
    background-color: #0493cc;
    margin-bottom: -100px;
  }

  &:after {
    height: 107px;
    transform: skewY(-3deg);
    top: 100px;
    content: '';
    display: block;
    position: relative;
    background-color: white;
    margin-bottom: -100px;
  }
`;

const VideoContainerDiv = styled.div`
  iframe {
    width: 100%;
    height: 100%;
  }

  width: 100%;
  height: 300px;
`;

function base64toBlob(base64Data: string) {
  const sliceSize = 1024;
  const byteCharacters = atob(base64Data);
  const bytesLength = byteCharacters.length;
  const slicesCount = Math.ceil(bytesLength / sliceSize);
  const byteArrays = new Array(slicesCount);

  for (let sliceIndex = 0; sliceIndex < slicesCount; sliceIndex += 1) {
    const begin = sliceIndex * sliceSize;
    const end = Math.min(begin + sliceSize, bytesLength);

    const bytes = new Array(end - begin);
    for (let offset = begin, i = 0; offset < end; i += 1, offset += 1) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: 'application/pdf' });
}

function openBase64NewTab(base64Pdf: string): void {
  const blob = base64toBlob(base64Pdf);
  if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
    (window.navigator as any).msSaveOrOpenBlob(blob, 'pdfBase64.pdf');
  } else {
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl);
  }
}

export function PageLanding() {
  const { slug, lang } = useParams();
  const navigate = useNavigate();
  const [locale] = useAppLocale();
  const { data: landing, isLoading } = useQuery(
    [QUERY.LANDING_POPUP, slug],
    () => getLandingPageBySlug(slug ?? 'ERROR'),
  );
  const [provinceAssistance, setProvinceAssistance] =
    useState<FetchProgramCertPoolResponseItem | null>(null);
  const [products, setProducts] = useState<ProductInfo[]>([]);
  const [key, setKey] = useState<number>(0);

  useEffect(() => {
    if (landing && landing?.products?.length > 0) {
      setProducts(landing.products);
    }
  }, [landing]);

  useEffect(() => {
    setKey(key + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  if (!landing) {
    return <Loading />;
  }

  if (!landing) {
    navigate(ROUTES.NOT_FOUND);
    return <></>;
  }

  const isRxHelpOne = !landing.promoCard.allowPageCustomization;
  const title = {
    en: convertHTMLToString(landing.promoCard.card.name),
    fr: convertHTMLToString(landing.promoCard.card.frName),
  };
  const subTitle = isRxHelpOne
    ? {
        en: landing.promoCard.card.tagLine,
        fr: landing.promoCard.card.frTagLine,
      }
    : { en: landing.promoCard.details, fr: landing.promoCard.frDetails };
  // eslint-disable-next-line no-nested-ternary
  const bgSx = isRxHelpOne
    ? 'url(/Homepage-banner-rxhelp-2000.jpg)'
    : landing.backgroundImage?.url
    ? `linear-gradient(to bottom, rgba(255,255,255,0.65), rgba(255,255,255,0.45)), url(${landing.backgroundImage.url})`
    : undefined;

  const qsForMedications = new URLSearchParams({
    province: provinceAssistance?.province?.key ?? '',
    program: landing.promoCard.card.encId,
    assistanceLevel: String(provinceAssistance?.assistance?.level ?? '#ERROR'),
    generationPoint: CertificateGenerationPoint.LandingPage,
    slug: slug ?? '',
    pdfOptions: landing.promoCard.card.pdfOptions,
    hideHeader:
      landing.promoCard.allowPageCustomization && !landing.isDisplayHeader
        ? 'true'
        : 'false',
    hideNavigation:
      landing.promoCard.allowPageCustomization && landing.isRestrictNavigation
        ? 'true'
        : 'false',
    hideFooter:
      landing.promoCard.allowPageCustomization && !landing.isDisplayFooter
        ? 'true'
        : 'false',
  });

  if (landing.headerLogo?.url) {
    qsForMedications.append('customLogoUrl', landing.headerLogo?.url);
  }

  interface ImportantContainerProps {
    landingItem: LandingItem;
  }

  function ImportantContainerDiv({ landingItem }: ImportantContainerProps) {
    return (
      <Container>
        {convertHTMLToText(
          l10n(
            {
              en: landingItem.importantInformationText ?? '',
              fr: landingItem.frImportantInformationText ?? '',
            },
            locale,
          ),
        ) && (
          <>
            <Box sx={{ mb: 3 }} />
            <Typography variant="h1">
              <FormattedMessage
                id="components.PageHome.importantInfo"
                defaultMessage="Important Information"
              />
            </Typography>
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: l10n(
                  {
                    en: landingItem.importantInformationText ?? '',
                    fr: landingItem.frImportantInformationText ?? '',
                  },
                  locale,
                ),
              }}
            />
          </>
        )}

        {!isRxHelpOne && (
          <>
            {!isRxHelpOne &&
              landingItem.isDisplayBrands &&
              products?.length > 0 && (
                <>
                  <Box sx={{ mb: 3 }} />
                  <Typography
                    variant="h1"
                    flexDirection={{ xs: 'column', sm: 'row' }}
                    style={{
                      display: 'flex',
                      alignItems: 'baseline',
                      whiteSpace: 'pre',
                    }}
                  >
                    {locale === Language.French && (
                      <>
                        <FormattedMessage
                          id="components.PageHome.programBrands"
                          defaultMessage="Program Brands"
                        />{' '}
                      </>
                    )}
                    {
                      <div
                        style={{ paddingRight: '8px', marginTop: '-1px' }}
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                          __html: l10n(
                            {
                              en: landingItem.promoCard.card.nameHtml,
                              fr: landingItem.promoCard.card.frNameHtml,
                            },
                            locale,
                          ),
                        }}
                      />
                    }
                    {locale === Language.English && (
                      <>
                        {' '}
                        <FormattedMessage
                          id="components.PageHome.programBrands"
                          defaultMessage="Program Brands"
                        />
                      </>
                    )}
                  </Typography>
                  <div
                    style={{ paddingRight: '8px', marginTop: '-1px' }}
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: l10n(
                        {
                          en: landingItem.brandText,
                          fr: landingItem.frBrandText,
                        },
                        locale,
                      ),
                    }}
                  />
                  <Grid container spacing={2}>
                    {products?.map((item) => (
                      <Grid item key={item.encId} xs={12} md={6} lg={3}>
                        <ListItem
                          id={item.encId}
                          title={l10n(
                            {
                              en: item.nameHtml,
                              fr: item.frNameHtml,
                            },
                            locale,
                          )}
                          label={l10n(
                            {
                              en: item.genericName,
                              fr: item.frGenericName,
                            },
                            locale,
                          )}
                          promoText=""
                          selected={false}
                          url={l10n(
                            {
                              en: item.logo?.content ?? '',
                              fr: item.frLogo?.content ?? '',
                            },
                            locale,
                          )}
                          noButton
                        />
                      </Grid>
                    ))}
                  </Grid>
                </>
              )}
          </>
        )}

        {!isRxHelpOne &&
          convertHTMLToText(
            l10n(
              {
                en: landingItem.footer ?? '',
                fr: landingItem.frFooter ?? '',
              },
              locale,
            ),
          ) && (
            <>
              <Box sx={{ mb: 3 }} />
              <div
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: l10n(
                    {
                      en: landingItem.footer ?? '',
                      fr: landingItem.frFooter ?? '',
                    },
                    locale,
                  ),
                }}
              />
            </>
          )}
      </Container>
    );
  }

  return (
    <>
      <Helmet>
        <title>{l10n(title, locale)}</title>
      </Helmet>

      <DefaultLayout
        hideHeader={
          landing.promoCard.allowPageCustomization && !landing.isDisplayHeader
        }
        hideNavigation={
          landing.promoCard.allowPageCustomization &&
          landing.isRestrictNavigation
        }
        hideFooter={
          landing.promoCard.allowPageCustomization && !landing.isDisplayFooter
        }
        customLogoUrl={landing.headerLogo?.url ?? undefined}
      >
        <Box
          sx={{
            backgroundImage: bgSx,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            py: 4,
            backgroundPositionX: 'center',
            backgroundPositionY: 'top',
            minHeight: bgSx ? '860px' : '2px',
          }}
        >
          <Container sx={{ py: 2, minHeight: '796px' }}>
            <Grid
              container
              spacing={2}
              sx={{
                paddingLeft: '16px',
                paddingRight: '16px',
                minHeight: '780px',
              }}
            >
              <Grid item xs={12} md={6}>
                {isRxHelpOne && (
                  <Box
                    sx={{
                      backgroundColor: 'rgba(0,0,0,0.4)',
                      display: 'inline-grid',
                      marginLeft: '-32px',
                      marginTop: '-80px',
                      marginBottom: '-32px',
                    }}
                  >
                    <Box sx={{ padding: '32px', paddingBottom: '102px' }}>
                      <Typography
                        variant="h1"
                        style={{ fontWeight: 'bold', fontSize: '3.25em' }}
                      >
                        <div
                          style={{ color: isRxHelpOne ? 'white' : 'black' }}
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{
                            __html: l10n(
                              {
                                en: landing.promoCard.card.nameHtml,
                                fr: landing.promoCard.card.frNameHtml,
                              },
                              locale,
                            ),
                          }}
                        />
                      </Typography>

                      <Box sx={{ mb: 3 }} />

                      {!isRxHelpOne && (
                        <>
                          <div
                            style={{ fontSize: '1.5em' }}
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{
                              __html: l10n(
                                {
                                  en: landing.promoCard.card.tagLine,
                                  fr: landing.promoCard.card.frTagLine,
                                },
                                locale,
                              ),
                            }}
                          />
                        </>
                      )}

                      <Box sx={{ mb: 1 }} />

                      <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={30}
                      >
                        <ProvinceDiv
                          style={{ display: 'inline-grid', width: '100%' }}
                        >
                          <ProvinceAssistanceSelector
                            key={key}
                            cardEncId={landing.promoCard.card.encId}
                            language={locale}
                            RenderInputProps={{
                              label: (
                                <FormattedMessage
                                  id="components.PageLanding.select.provinceAssistanceLevel.label"
                                  defaultMessage="Select Province"
                                />
                              ),
                            }}
                            changeLabelNoProvince
                            size="small"
                            value={provinceAssistance}
                            onChange={(_, value) => {
                              setProvinceAssistance(value);
                              if (value?.province) {
                                const prod = landing.products.filter((item) => {
                                  return item.provinces.find(
                                    (pr) => pr === value?.province?.key,
                                  );
                                });

                                if (prod) {
                                  setProducts(prod);
                                }
                              } else {
                                setProducts(landing.products);
                              }
                            }}
                          />

                          <Button
                            style={buttonStyle}
                            variant="contained"
                            disabled={!provinceAssistance}
                            href={
                              landing?.promoCard?.card?.pdfOptions ===
                                'Upload' && landing?.customPdfFile
                                ? ''
                                : `${generatePath(ROUTES.WIZARD, {
                                    lang: lang ?? 'en',
                                  })}?${qsForMedications}`
                            }
                            onClick={() => {
                              if (
                                landing?.promoCard?.card?.pdfOptions ===
                                  'Upload' &&
                                landing?.customPdfFile
                              ) {
                                openBase64NewTab(landing?.customPdfFile);
                              }
                            }}
                          >
                            {landing.promoCard.card.pdfOptions === 'None' && (
                              <FormattedMessage
                                id="0"
                                defaultMessage="Add Your Free Card"
                              />
                            )}
                            {landing.promoCard.card.pdfOptions !== 'None' && (
                              <FormattedMessage
                                id="components.PageHome.getCard"
                                defaultMessage="Get Your Free Card"
                              />
                            )}
                          </Button>
                        </ProvinceDiv>
                      </Stack>

                      <Box sx={{ mb: 1 }} />

                      {isRxHelpOne &&
                        l10n(
                          {
                            en: landing.promoCard.card.enLogo?.url ?? '',
                            fr: landing.promoCard.card.frLogo?.url ?? '',
                          },
                          locale,
                        ) && (
                          <>
                            <Box sx={{ mt: 2, maxWidth: { xs: '100%' } }}>
                              <RxHelpCard
                                cert=""
                                group=""
                                layout={
                                  landing.promoCard.card.certificatePosition
                                }
                                certificateTextColor={
                                  landing.promoCard.card.certificateTextColor
                                }
                                imgUrl={l10n(
                                  {
                                    en:
                                      landing.promoCard.card.enLogo?.url ?? '',
                                    fr:
                                      landing.promoCard.card.frLogo?.url ?? '',
                                  },
                                  locale,
                                )}
                                noLabels
                              />
                            </Box>

                            {convertHTMLToString(l10n(subTitle, locale)) && (
                              <div
                                style={{ color: 'white' }}
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={{
                                  __html: l10n(subTitle, locale),
                                }}
                              />
                            )}
                          </>
                        )}
                    </Box>
                  </Box>
                )}

                {!isRxHelpOne && (
                  <>
                    <Typography
                      variant="h1"
                      fontSize={{ xs: '2.25em', sm: '3.25em' }}
                      style={{ fontWeight: 'bold' }}
                    >
                      <div
                        style={{ color: isRxHelpOne ? 'white' : 'black' }}
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                          __html: l10n(
                            {
                              en: landing.promoCard.card.nameHtml,
                              fr: landing.promoCard.card.frNameHtml,
                            },
                            locale,
                          ),
                        }}
                      />
                    </Typography>

                    <Box sx={{ mb: 3 }} />

                    {!isRxHelpOne && (
                      <>
                        <div
                          style={{ fontSize: '1.5em' }}
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{
                            __html: l10n(
                              {
                                en: landing.promoCard.card.tagLine,
                                fr: landing.promoCard.card.frTagLine,
                              },
                              locale,
                            ),
                          }}
                        />
                      </>
                    )}

                    <Box sx={{ mb: 1 }} />

                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={30}>
                      <ProvinceDiv
                        style={{ display: 'inline-grid', width: '100%' }}
                      >
                        <ProvinceAssistanceSelector
                          key={key}
                          cardEncId={landing.promoCard.card.encId}
                          language={locale}
                          RenderInputProps={{
                            label: (
                              <FormattedMessage
                                id="components.PageLanding.select.provinceAssistanceLevel.label"
                                defaultMessage="Select Province"
                              />
                            ),
                          }}
                          changeLabelNoProvince
                          size="small"
                          value={provinceAssistance}
                          onChange={(_, value) => {
                            setProvinceAssistance(value);
                            if (value?.province) {
                              const prod = landing.products.filter((item) => {
                                return item.provinces.find(
                                  (pr) => pr === value?.province?.key,
                                );
                              });

                              if (prod) {
                                setProducts(prod);
                              }
                            } else {
                              setProducts(landing.products);
                            }
                          }}
                        />

                        <Button
                          style={buttonStyle}
                          variant="contained"
                          disabled={!provinceAssistance}
                          href={
                            landing?.promoCard?.card?.pdfOptions === 'Upload' &&
                            landing?.customPdfFile
                              ? ''
                              : `${generatePath(ROUTES.WIZARD, {
                                  lang: lang ?? 'en',
                                })}?${qsForMedications}`
                          }
                          onClick={() => {
                            if (
                              landing?.promoCard?.card?.pdfOptions ===
                                'Upload' &&
                              landing?.customPdfFile
                            ) {
                              openBase64NewTab(landing?.customPdfFile);
                            }
                          }}
                        >
                          {landing.promoCard.card.pdfOptions === 'None' && (
                            <FormattedMessage
                              id="0"
                              defaultMessage="Add Your Free Card"
                            />
                          )}
                          {landing.promoCard.card.pdfOptions !== 'None' && (
                            <FormattedMessage
                              id="components.PageHome.getCard"
                              defaultMessage="Get Your Free Card"
                            />
                          )}
                        </Button>
                      </ProvinceDiv>
                    </Stack>

                    <Box sx={{ mb: 2 }} />

                    {!isRxHelpOne && (
                      <Box
                        sx={{
                          width: '100%',
                          height: '100%',
                          maxHeight: '301px',
                        }}
                      >
                        {l10n(
                          {
                            en: landing.videoUrl ?? '',
                            fr: landing.frVideoUrl ?? '',
                          },
                          locale,
                        ) !== '' && (
                          <VideoContainerDiv>
                            <ReactPlayer
                              url={l10n(
                                {
                                  en: landing.videoUrl ?? '',
                                  fr: landing.frVideoUrl ?? '',
                                },
                                locale,
                              )}
                              width="100%"
                              height="100%"
                              controls
                            />
                          </VideoContainerDiv>
                        )}
                      </Box>
                    )}

                    {isRxHelpOne &&
                      l10n(
                        {
                          en: landing.promoCard.card.enLogo?.url ?? '',
                          fr: landing.promoCard.card.frLogo?.url ?? '',
                        },
                        locale,
                      ) && (
                        <>
                          <Box sx={{ mt: 2, maxWidth: { xs: '100%' } }}>
                            <RxHelpCard
                              cert=""
                              group=""
                              layout={
                                landing.promoCard.card.certificatePosition
                              }
                              certificateTextColor={
                                landing.promoCard.card.certificateTextColor
                              }
                              imgUrl={l10n(
                                {
                                  en: landing.promoCard.card.enLogo?.url ?? '',
                                  fr: landing.promoCard.card.frLogo?.url ?? '',
                                },
                                locale,
                              )}
                              noLabels
                            />
                          </Box>

                          {convertHTMLToString(l10n(subTitle, locale)) && (
                            <div
                              style={{ color: 'white' }}
                              // eslint-disable-next-line react/no-danger
                              dangerouslySetInnerHTML={{
                                __html: l10n(subTitle, locale),
                              }}
                            />
                          )}
                        </>
                      )}
                  </>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                {!isRxHelpOne &&
                  l10n(
                    {
                      en: landing.promoCard.card.enLogo?.url ?? '',
                      fr: landing.promoCard.card.frLogo?.url ?? '',
                    },
                    locale,
                  ) && (
                    <Box
                      sx={{
                        mt: 2,
                        maxWidth: { xs: '100%' },
                      }}
                    >
                      <RxHelpCard
                        cert=""
                        group=""
                        layout={landing.promoCard.card.certificatePosition}
                        certificateTextColor={
                          landing.promoCard.card.certificateTextColor
                        }
                        imgUrl={l10n(
                          {
                            en: landing.promoCard.card.enLogo?.url ?? '',
                            fr: landing.promoCard.card.frLogo?.url ?? '',
                          },
                          locale,
                        )}
                        noLabels
                      />
                    </Box>
                  )}
              </Grid>
            </Grid>
          </Container>
        </Box>

        <ImportantContainerDiv landingItem={landing} />

        {isRxHelpOne && (
          <ImportantInfoDiv>
            <ImportantInfoAfterDiv className="important-info-after" />
            <Box paddingTop="100px" />
            <ImportantContainerDiv landingItem={landing} />
          </ImportantInfoDiv>
        )}
      </DefaultLayout>
    </>
  );
}
