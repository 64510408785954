import { httpClient } from '../httpClient';
import { CardLayout } from '../../components/RxHelpCard';
import { Base64Image } from '../types';
import { Language } from '../../Language';
import { CertificateGenerationPoint } from '../../CertificateGenerationPoint';

export interface GenerateCardResponse {
  encId: string;
  cardImage: {
    encId: string;
    name: string;
    content: Base64Image;
  };
  frCardImage: {
    encId: string;
    name: string;
    content: Base64Image;
  };
  certNumber: string;
  groupCode: string;
  certificateTextColor: string;
  certificatePosition: CardLayout;
  pdfOptions: number;
  pdfSettings: number;
}

export interface GenerateCardParams {
  /**
   * List of product encId's
   * @example ['mbQjQaBNwEd6nrx', 'tqUjaDCNgEd4kxr']
   */
  productIds: Array<string>;

  /**
   * assistanceLevel percentage.
   *
   * @note Optional in case user came from Home page.
   * @example 10
   */
  assistanceLevel?: number;

  /**
   * Province code
   * @example 'AB'
   */
  province: string;

  /**
   * Certificate Pools are language specific, so we need to choose correct one
   * based on language of UI
   */
  language: Language;

  /**
   * certificateCardId - cardId for generating certificate
   */
  certificateCardId: string | undefined;

  /**
   * Page where certificate generated.
   */
  generationPoint: CertificateGenerationPoint;

  /**
   * slug - slug of the page
   */
  slug: string | null;

  /**
   * email for marketing purpose
   */
  notificationEmail: string | null;
}

interface GenerateCardInternalParams {
  productIds: Array<string>;
  province: string;
  language: 'En' | 'Fr';
}

const LANG_MAP = {
  [Language.English]: 'En',
  [Language.French]: 'Fr',
} as const;

export function generateCard(
  params: GenerateCardParams,
): Promise<GenerateCardResponse[]> {
  const mapParams: GenerateCardInternalParams = {
    ...params,
    language: LANG_MAP[params.language],
  };
  return httpClient.post<GenerateCardInternalParams, GenerateCardResponse[]>(
    '/certificates',
    mapParams,
  );
}
