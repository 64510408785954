export enum Language {
  English = 'en',
  French = 'fr',
}

interface L10nMapper {
  en: string;
  fr: string;
}

export function l10n(data: L10nMapper, locale: Language) {
  if (locale === Language.French) {
    return data.fr;
  }
  return data.en;
}
