import React from 'react';
import { useQuery } from 'react-query';
import { useIntl } from 'react-intl';
import { Alert, Container } from '@mui/material';
import { Helmet } from 'react-helmet';
import { DefaultLayout } from '../../layouts/DefaultLayout';
import { l10n } from '../../Language';
import { useAppLocale } from '../../components/AppLocaleProvider';
import { getAboutInfo } from '../../lib/api/getAboutInfo';
import { QUERY } from '../../queryClient';
import { Loading } from '../../components/Loading';
import { PharmacyLocator } from '../../components/PharmacyLocator';

export function PageAbout() {
  const intl = useIntl();
  const [locale] = useAppLocale();
  const { isLoading, data, error } = useQuery([QUERY.ABOUT], () =>
    getAboutInfo(),
  );

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'components.PageAbout.meta.title',
            defaultMessage: 'About Us',
          })}
        </title>
      </Helmet>

      <DefaultLayout>
        <Container sx={{ py: 2 }}>
          {/* eslint-disable-next-line no-nested-ternary */}
          {isLoading ? (
            <Loading />
          ) : error ? (
            <Alert severity="error">{String(error)}</Alert>
          ) : (
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: l10n(
                  { en: data?.details ?? '', fr: data?.frDetails ?? '' },
                  locale,
                ),
              }}
            />
          )}
        </Container>

        <PharmacyLocator />
      </DefaultLayout>
    </>
  );
}
