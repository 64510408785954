import React, { useEffect, useState } from 'react';
import { generatePath, useParams, useSearchParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import { useQuery } from 'react-query';
import {
  Alert,
  Box,
  Breadcrumbs,
  Container,
  Link,
  Pagination,
  Stack,
  Typography,
} from '@mui/material';
import { GoogleMap, LoadScriptNext, MarkerF } from '@react-google-maps/api';
import { DefaultLayout } from '../../layouts/DefaultLayout';
import { PharmacyCard } from '../../components/PharmacyCard';
import {
  SearchBy,
  SearchPharmacyItem,
  searchPharmacy,
} from '../../lib/api/searchPharmacy';
import { ROUTES } from '../../router';
import { QUERY } from '../../queryClient';
import { env } from '../../app/.shared/settings/env';

const KEY = env.GMAP_KEY;
const mapContainerStyle = {
  width: '100%',
  height: 500,
};

interface Position {
  latitude: number;
  longitude: number;
}

export function PagePharmacyLocatorSERP() {
  const intl = useIntl();
  const { lang } = useParams();
  const [position, setPosition] = useState<Position | null>(null);

  const [page, setPage] = useState(1);

  const [searchParams] = useSearchParams();
  const searchByRaw = searchParams.get('searchBy');
  const searchBy = Object.values(SearchBy).includes(searchByRaw as SearchBy)
    ? (searchByRaw as SearchBy)
    : SearchBy.PharmacyName;
  const postalCode = searchParams.get('postalCode') ?? undefined;
  const within = searchParams.get('within') ?? undefined;
  const city = searchParams.get('city') ?? undefined;
  const province = searchParams.get('province') ?? undefined;
  const name = searchParams.get('pharmacyName') ?? undefined;

  const queryParams = {
    searchBy,
    postalCode,
    city,
    name,
    within,
    province,
    page,
    pageSize: 10,
  };
  const { data } = useQuery([QUERY.PHARMACIES, queryParams], () =>
    searchPharmacy(queryParams),
  );

  const [zoom, setZoom] = useState(16);
  const [locations, setLocations] = useState<SearchPharmacyItem[]>([]);

  useEffect(() => {
    if (!navigator.geolocation) {
      // eslint-disable-next-line no-console
      console.error('Geolocation is not supported by user browser');
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (pos) => {
        setPosition(pos.coords);
      },
      () => {
        // eslint-disable-next-line no-console
        console.error('Unable to retrieve user location');
      },
    );
  }, []);

  useEffect(() => {
    if (data?.items?.length) {
      setLocations(
        data?.items.filter((item) => {
          return !(item.latitude === 0 && item.longitude === 0);
        }),
      );
    } else {
      setLocations([
        {
          latitude: 43.65107,
          longitude: -79.347015,
        } as SearchPharmacyItem,
      ]);
    }
  }, [data]);

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'components.PagePharmacyLocatorSERP.meta.title',
            defaultMessage: 'Pharmacy Locator — Search Results',
          })}
        </title>
      </Helmet>

      <DefaultLayout>
        <Container sx={{ my: 2 }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              color="inherit"
              href={generatePath(ROUTES.HOME, { lang: lang ?? 'en' })}
            >
              <FormattedMessage
                id="components.PagePharmacyLocatorSERP.breadcrumbs.home"
                defaultMessage="RxHelp"
              />
            </Link>
            <Link
              underline="hover"
              color="inherit"
              href={generatePath(ROUTES.PHARMACY_LOCATOR, {
                lang: lang ?? 'en',
              })}
            >
              <FormattedMessage
                id="components.PagePharmacyLocatorSERP.breadcrumbs.pharmacyLocator"
                defaultMessage="Pharmacy Locator"
              />
            </Link>
            <Typography color="text.primary">
              <FormattedMessage
                id="components.PagePharmacyLocatorSERP.breadcrumbs.serp"
                defaultMessage="Search Results"
              />
            </Typography>
          </Breadcrumbs>

          <Box sx={{ mb: 2 }} />
          {data && (
            <LoadScriptNext googleMapsApiKey={KEY ?? ''}>
              <GoogleMap
                zoom={zoom}
                mapContainerStyle={mapContainerStyle}
                onLoad={(map) => {
                  const bounds = new window.google.maps.LatLngBounds();
                  locations.forEach((item) => {
                    bounds.extend({ lat: item.latitude, lng: item.longitude });
                  });
                  map.fitBounds(bounds);
                  if (locations.length === 1) {
                    setTimeout(() => {
                      setZoom(data?.items?.length ? 17 : 8);
                    }, 300);
                  }
                }}
              >
                {data?.items?.length ? (
                  locations.map((item) => {
                    return (
                      <MarkerF
                        label={item.name}
                        key={`${item.encId}-${item.latitude}-${item.longitude}`}
                        position={{ lat: item.latitude, lng: item.longitude }}
                      />
                    );
                  })
                ) : (
                  <></>
                )}
              </GoogleMap>
            </LoadScriptNext>
          )}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="body1" sx={{ my: 2, fontSize: '1.25em' }}>
              {searchBy === SearchBy.PostalCode && !within && (
                <FormattedMessage
                  id="components.PagePharmacyLocatorSERP.searchBy.postalCode"
                  defaultMessage="By Postal Code: {code}"
                  values={{
                    code: postalCode,
                  }}
                />
              )}
              {searchBy === SearchBy.PostalCode && within && (
                <FormattedMessage
                  id="components.PagePharmacyLocatorSERP.searchBy.postalCode"
                  defaultMessage="By Postal Code: {code} within {within}"
                  values={{
                    code: postalCode,
                    within,
                  }}
                />
              )}
              {searchBy === SearchBy.City && (
                <FormattedMessage
                  id="components.PagePharmacyLocatorSERP.searchBy.postalCode"
                  defaultMessage="By City: {city} province {province}"
                  values={{
                    city,
                    province,
                  }}
                />
              )}
              {searchBy === SearchBy.PharmacyName && (
                <FormattedMessage
                  id="components.PagePharmacyLocatorSERP.searchBy.pharmacyName"
                  defaultMessage="By Pharmacy Name: {name}"
                  values={{
                    name,
                  }}
                />
              )}
            </Typography>

            <Link
              href={generatePath(ROUTES.PHARMACY_LOCATOR, {
                lang: lang ?? 'en',
              })}
            >
              <FormattedMessage
                id="components.PagePharmacyLocatorSERP.button.editSearch"
                defaultMessage="Edit Search"
              />
            </Link>
          </Box>

          {data && data?.items.length <= 0 && (
            <Box sx={{ minHeight: '5vh', my: 2 }}>
              <Alert severity="warning">
                <FormattedMessage id="0" defaultMessage="No pharmacy found" />
              </Alert>
            </Box>
          )}

          <Stack direction="column" spacing={2}>
            {data?.items.map((item) => (
              <PharmacyCard
                key={item.encId}
                encId={item.encId}
                title={item.name}
                address={item.address}
                phone={item.phoneNumber}
              />
            ))}
          </Stack>

          {(data?.totalPages ?? 1) > 1 && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <Pagination
                size="large"
                count={data?.totalPages ?? 1}
                page={page}
                onChange={(_, value) => setPage(value)}
              />
            </Box>
          )}
        </Container>
      </DefaultLayout>
    </>
  );
}
