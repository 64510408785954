import { httpClient } from '../httpClient';
import type { DateTime, HTML } from '../types';

interface GetPrivacyPolicyResponse {
  encId: string;
  details: HTML;
  frDetails: HTML;
  slug: string;
  createDate: DateTime;
  modifiedDate: DateTime;
}

const SLUG = 'privacy';

export function getPrivacyPolicy() {
  return httpClient.get<void, GetPrivacyPolicyResponse>(
    `/contents?slug=${SLUG}`,
  );
}
