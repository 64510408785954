import React from 'react';
import { useIntl } from 'react-intl';
import { ButtonBase, Stack } from '@mui/material';
import { useThemeFontSize } from '../AppThemeProvider';
import { ThemeFontSize } from '../AppThemeProvider/theme';
import { SizeIcon } from './SizeIcon';

export function FontSizeSelector() {
  const intl = useIntl();
  const [size, setSize] = useThemeFontSize();

  return (
    <Stack direction="row">
      <ButtonBase
        aria-label={intl.formatMessage({
          id: 'components.FontSizeSelector.button.large',
          defaultMessage: 'Large',
        })}
        onClick={() => setSize(ThemeFontSize.Large)}
      >
        <SizeIcon
          color={size === ThemeFontSize.Large ? 'secondary' : undefined}
          fontSize="large"
        />
      </ButtonBase>

      <ButtonBase
        aria-label={intl.formatMessage({
          id: 'components.FontSizeSelector.button.medium',
          defaultMessage: 'Medium',
        })}
        onClick={() => setSize(ThemeFontSize.Medium)}
      >
        <SizeIcon
          color={size === ThemeFontSize.Medium ? 'secondary' : undefined}
          fontSize="medium"
        />
      </ButtonBase>

      <ButtonBase
        aria-label={intl.formatMessage({
          id: 'components.FontSizeSelector.button.small',
          defaultMessage: 'Small',
        })}
        onClick={() => setSize(ThemeFontSize.Small)}
      >
        <SizeIcon
          color={size === ThemeFontSize.Small ? 'secondary' : undefined}
          fontSize="small"
        />
      </ButtonBase>
    </Stack>
  );
}
