import { httpClient } from '../httpClient';

export interface EmailPharmacyDetailsData {
  encId: string;
  email: string;
}

export function emailPharmacyDetails(payload: EmailPharmacyDetailsData) {
  return httpClient.post(
    `/pharmacies/${payload.encId}/emails`,
    JSON.stringify(payload.email),
    { headers: { 'Content-Type': 'application/json' } },
  );
}
